<template>
  <sdPageHeader title="Create Product"> </sdPageHeader>
  <Main>
    <a-row :gutter="25">
      <a-col :sm="24" :xs="24">
        <WizardBlock>
          <sdCards headless>
            <a-row justify="center">
              <a-col :xxl="20" :xs="24">
                <CreateWizard />
              </a-col>
            </a-row>
          </sdCards>
        </WizardBlock>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { WizardBlock } from '../style';
import { Main } from '@/view/styled';
import CreateWizard from './CreateWizard.vue';

const Wizards = {
  name: 'Wizards',
  components: { WizardBlock, Main, CreateWizard },
};

export default Wizards;
</script>
