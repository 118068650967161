<template>
  <TopMenuStyle>
    <div class="strikingDash-top-menu">
      <ul>
        <li v-if="!isAdmin && hasRole('orders')" class="has-subMenu">
          <a class="parent">
            <router-link to="/omni/orders"> Orders </router-link>
          </a>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/orders"> View All </router-link>
            </li>
            <!-- <li @click="addParentActive">
              <router-link to="/omni/draft-orders">
                Draft Orders
              </router-link>
            </li>
            <li @click="addParentActive">
              <router-link to="/omni/orders/open-carts">
                Open Carts
              </router-link>
            </li>
            <li @click="addParentActive">
              <router-link to="/omni/orders/create">
                Add New
              </router-link>
            </li> -->
          </ul>
        </li>
        <li v-if="!isAdmin && hasRole('products')" class="has-subMenu">
          <router-link to="/omni/products"> Products </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/products"> View All </router-link>
            </li>
            <li @click="addParentActive">
              <router-link to="/omni/product/create"> Add New </router-link>
            </li>
          </ul>
        </li>
        <li v-if="!isAdmin && hasRole('customers')" class="has-subMenu">
          <router-link to="/omni/customers"> Customers </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/customers"> View All </router-link>
            </li>
            <li @click="addParentActive">
              <router-link :to="`/business`"> Add New </router-link>
            </li>
          </ul>
        </li>
        <li v-if="!isAdmin && hasRole('orders')" class="has-subMenu">
          <router-link to="/omni/enquiries"> Enquiries </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/enquiries"> View All </router-link>
            </li>
            <li @click="addParentActive">
              <router-link to="/omni/enquiries/create"> Add New </router-link>
            </li>
          </ul>
        </li>
        <li v-if="!isAdmin && hasRole('appointments')" class="has-subMenu">
          <router-link to="/omni/appointments"> Appointments </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/appointments"> View All </router-link>
            </li>
            <!-- <li @click="addParentActive">
              <router-link to="/omni/appointments/details">
                Details
              </router-link>
            </li> -->
          </ul>
        </li>
        <!-- <li v-if="isAdmin || hasRole('orders')" class="has-subMenu">
          <router-link to="/omni/reporting"> Report </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/reporting"> View All </router-link>
            </li>
             <li @click="addParentActive">
              <router-link to="/omni/appointments/details">
                Details
              </router-link>
            </li>
          </ul>
        </li> -->
        <li v-if="!isAdmin && hasRole('marketing')" class="has-subMenu">
          <router-link to="/omni/marketing"> Marketing </router-link>
          <ul class="subMenu">
            <li @click="addParentActive">
              <router-link to="/omni/marketing"> View All </router-link>
            </li>
            <!-- <li @click="addParentActive">
              <router-link to="/omni/appointments/details">
                Details
              </router-link>
            </li> -->
          </ul>
        </li>
        <li v-if="isAdmin">
          <router-link to="/admin/omni/storeviews"> StoreViews </router-link>
        </li>
        <li v-if="isAdmin">
          <router-link to="/admin/omni/clients"> Clients </router-link>
        </li>
        <li v-if="isAdmin">
          <router-link to="/admin/omni/reporting"> Registration Lookup Report </router-link>
        </li>
        <li v-if="isAdmin">
          <router-link to="/admin/omni/user-logs"> User Logs </router-link>
        </li>
      </ul>
    </div>
  </TopMenuStyle>
</template>
<script>
import { onMounted, computed } from 'vue';
import { TopMenuStyle } from './style';
import { useStore } from 'vuex';

export default {
  name: 'TopMenu',
  components: {
    TopMenuStyle,
  },
  setup() {
    const { state } = useStore();

    const isAdmin = computed(() => state.auth.user && !!state.auth.user.is_admin);
    const userRoles = computed(() => state.auth.user.scopes.map((scope) => scope?.name));

    const hasRole = (role) => userRoles.value.includes(role);

    onMounted(() => {
      const active = document.querySelector('.strikingDash-top-menu a.active');
      const activeDefault = () => {
        const megaMenu = active.closest('.megaMenu-wrapper');
        const hasSubMenuLeft = active.closest('.has-subMenu-left');
        if (!megaMenu) {
          active.closest('ul').previousSibling.classList.add('active');
          if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
        } else {
          active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
        }
      };
      window.addEventListener('load', active && activeDefault);
      // return () => window.removeEventListener('load', activeDefault);
    });

    const addParentActive = (event) => {
      document.querySelectorAll('.parent').forEach((element) => {
        element.classList.remove('active');
      });

      const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
      const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
      if (!megaMenu) {
        event.currentTarget.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };

    return { addParentActive, isAdmin, hasRole };
  },
};
</script>
