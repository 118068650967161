<template>
  <FullHeightSdCardWrapper>
    <ItemsToShipWrapper>
      <sdPageHeader title="Invoiced Items" />
      <a-row justify="center" :gutter="25">
        <a-col :xs="24">
          <sdCards>
            <TableWrapper class="table-responsive">
              <a-table :dataSource="tableData" :columns="tableColumns" :pagination="{ defaultPageSize: 5 }" />
            </TableWrapper>
          </sdCards>
        </a-col>
      </a-row>

      <sdPageHeader title="Order Total" />
      <a-row justify="center" :gutter="25" class="order-total">
        <a-col :md="12" :xs="24">
          <sdCards title="Credit Memo Comments">
            <a-row>
              <a-col :xs="24">
                <div class="comment-label"><b>Comment Text</b></div>
                <a-textarea v-model:value="orderTotal.comment" placeholder="Write Your comments" :rows="6" />
              </a-col>
            </a-row>
          </sdCards>
        </a-col>
        <a-col :md="12" :xs="24">
          <sdCards title="Refund Totals" class="refund-total">
            <a-row justify="space-between">
              <a-col>Subtotal</a-col>
              <a-col>{{ subTotal }}</a-col>
            </a-row>
            <a-row justify="space-between">
              <a-col>Refund Shipping</a-col>
              <a-col>
                <a-input type="number" v-model:value="orderTotal.shipping_amount" />
              </a-col>
            </a-row>
            <a-row justify="space-between">
              <a-col>Adjustment Refund</a-col>
              <a-col>
                <a-input type="number" v-model:value="orderTotal.adjustment_negative" />
              </a-col>
            </a-row>
            <a-row justify="space-between">
              <a-col>Adjustment Fee</a-col>
              <a-col>
                <a-input type="number" v-model:value="orderTotal.adjustment_positive" />
              </a-col>
            </a-row>
            <a-divider type="horizontal" />
            <a-row justify="space-between">
              <a-col><b>Grand Total</b></a-col>
              <a-col
                ><b>{{ grandTotal }}</b></a-col
              >
            </a-row>
            <a-row justify="start">
              <a-checkbox disabled>Append Comments</a-checkbox>
            </a-row>
            <a-row justify="start">
              <a-checkbox>Email Copy of Credit Memo</a-checkbox>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>

      <InformationPageHeader>
        <sdButton class="btn-refund" type="warning" @click="doRefund">Refund</sdButton>
      </InformationPageHeader>
    </ItemsToShipWrapper>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref, onMounted, watch, reactive } from 'vue';
import { ItemsToShipWrapper } from '../../invoices/style';
import { FullHeightSdCardWrapper, TableWrapper } from '@/view/styled';
import { InformationPageHeader } from '../../information/style';
import { useRoute } from 'vue-router';
import { Notification } from 'ant-design-vue';

const tableColumns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Refundable',
    dataIndex: 'refundable',
    key: 'refundable',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
  },
  {
    title: 'Return to Stock',
    dataIndex: 'return_to_stock',
    key: 'return_to_stock',
  },
  {
    title: 'Qty To Refund',
    dataIndex: 'qty_to_refund',
    key: 'qty_to_refund',
  },
  {
    title: 'Subtotal',
    dataIndex: 'sub_total',
    key: 'sub_total',
  },
  {
    title: 'Tax Amount',
    dataIndex: 'tax_amount',
    key: 'tax_amount',
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discount_amount',
    key: 'discount_amount',
  },
  {
    title: 'Row total',
    dataIndex: 'row_total',
    key: 'row_total',
    align: 'left',
  },
];

export default {
  name: 'ItemsToRefund',
  components: {
    ItemsToShipWrapper,
    FullHeightSdCardWrapper,
    TableWrapper,
    InformationPageHeader,
  },
  setup() {
    const { state, dispatch, commit } = useStore();
    const order = computed(() => state.orders.order);
    const itemQtys = ref({});
    const { params } = useRoute();

    const items = computed(() => {
      return state.orders.order.items ? state.orders.order.items.filter((item) => item.qty_invoiced > 0) : [];
    });

    const reset = () => {
      Object.values(order.value).length &&
        order.value.items.map((item) => {
          itemQtys.value[item.item_id] = null;
        });
    };

    onMounted(() => {
      if (!state.orders.invoice) {
        commit('setInvoice', params['invoiceId']);
      }
      reset();
    });

    watch(order, () => {
      reset();
    });

    const tableData = computed(() =>
      items.value.length
        ? items.value.map((item) => {
            const { item_id, name, sku, qty_ordered, qty_refunded, qty_invoiced, price, tax_amount, discount_amount } =
              item;

            const refundableStatus = +qty_invoiced - +qty_refunded > 0 ? 'Yes' : 'No';

            const labelColors = {
              Yes: 'magenta',
              No: 'red',
            };

            return {
              key: item_id,
              product: (
                <div>
                  <div>
                    {name} - {sku}
                  </div>
                  <div>SKU: {sku}</div>
                </div>
              ),
              refundable: (
                <div class="taglist-wrap">
                  <a-tag color={labelColors[refundableStatus]}>{refundableStatus}</a-tag>
                </div>
              ),
              price,
              qty: (
                <div>
                  <div>Ordered {qty_ordered}</div>
                  <div>Invoiced {qty_invoiced}</div>
                </div>
              ),
              return_to_stock: '',
              qty_to_refund: (
                <div>
                  <>
                    <a-input
                      value={itemQtys.value[item_id]}
                      type="number"
                      max={qty_ordered}
                      min="0"
                      disabled={refundableStatus === 'No'}
                      onChange={(e) => (itemQtys.value[item_id] = e.target.value)}
                    />
                  </>
                </div>
              ),
              sub_total: (itemQtys.value[item_id] * price).toFixed(2),
              tax_amount,
              discount_amount,
              row_total: (itemQtys.value[item_id] * price).toFixed(2),
            };
          })
        : [],
    );

    const orderTotal = reactive({
      comment: '',
      shipping_amount: 0,
      adjustment_positive: 0,
      adjustment_negative: 0,
    });

    const subTotal = computed(() => {
      return items.value.length
        ? items.value.reduce((total, { item_id, price }) => {
            total += Number((itemQtys.value[item_id] * price).toFixed(2));

            return total;
          }, 0)
        : 0;
    });

    const grandTotal = computed(() => {
      return Number(
        subTotal.value +
          Number(orderTotal.shipping_amount) +
          Number(orderTotal.adjustment_positive) +
          Number(orderTotal.adjustment_negative),
      ).toFixed(2);
    });

    // const { push } = useRouter();
    // const locale = computed(() => {
    //   return {
    //     emptyText: (
    //       <sdButton class="btn-signin" type="warning" onClick={() => push({ name: 'omni-order-invoice-view-refund' })}>
    //         New Credit Memo
    //       </sdButton>
    //     ),
    //   };
    // });
    const doRefund = async () => {
      if (!orderTotal.comment) {
        Notification['warning']({
          message: `Comment can't be empty value.`,
          description: 'Please input comment before submitting.',
        });

        return;
      }

      const payload = {
        items: [],
        arguments: {
          adjustment_positive: +orderTotal.adjustment_positive,
          adjustment_negative: +orderTotal.adjustment_negative,
          shipping_amount: +orderTotal.shipping_amount,
        },
        appendComment: true,
        comment: {
          comment: orderTotal.comment,
          is_visible_on_front: 1,
        },
        notify: true,
      };
      payload.items = Object.entries(itemQtys.value).map(([key, value]) => ({
        order_item_id: key,
        qty: value,
      }));

      await dispatch('refundOrder', {
        payload,
        orderId: params['id'],
        grandTotal: grandTotal.value,
      });
    };

    return {
      order,
      reset,
      items,
      itemQtys,
      tableColumns,
      tableData,
      orderTotal,
      doRefund,
      subTotal,
      grandTotal,
    };
  },
};
</script>
