<template>
  <sdCards title="Client Resources">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="addNew" :disabled="isSaving">+ Add New Technician</sdButton>
    </template>
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <AttributesTable />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>

  <a-modal
    v-model:visible="visibleEditModal"
    :title="modalTitle"
    @ok="onSave"
    width="80%"
    :dialog-style="{ top: '5px' }"
  >
    <BasicFormWrapper>
      <LocationFormWrapper>
        <a-form name="multi-form" layout="horizontal">
          <a-row :gutter="30">
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Technician Name">
                <a-input v-model:value="attribute.name" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Storeview">
                <a-input v-model:value="attribute.store_views" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Timezone">
                <a-input v-model:value="attribute.timezone" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Working Days">
                <a-input v-model:value="attribute.working_days" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </LocationFormWrapper>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import { FileCardWrap, LocationFormWrapper } from './style';
import AttributesTable from './components/ResourceTable.vue';
import { computed, ref, watch, onMounted } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

export default {
  name: 'ClientLocation',
  components: { FileCardWrap, AttributesTable, BasicFormWrapper, LocationFormWrapper },
  setup() {
    const { state, commit, dispatch } = useStore();
    const { params } = useRoute();
    console.log(params.id, 'id');
    const clientStoreviews = ref([]);

    const allStoreviews = computed(() => clientStoreviews.value.map((d) => d.code));

    const visibleEditModal = computed({
      get() {
        return state.clients.visibleResourceEditModal;
      },
      set(value) {
        commit('toggleResourceEditModal', value);
      },
    });
    const attribute = ref(() => {});
    const modalTitle = computed(() => {
      if (attribute.value.id > 0) {
        return `Update Resource`;
      } else {
        return `Register a new Resource`;
      }
    });

    const addNew = () => {
      commit('initResource');
      commit('toggleResourceEditModal', true);
    };
    const onSave = async () => {
      commit('setResource', attribute.value);
      commit('toggleResourceEditModal', false);
      if (attribute.value.id > 0) {
        await dispatch('updateResource', { id: attribute.value.id });
      } else {
        await dispatch('createResource');
      }
    };

    watch(
      () => state.clients.visibleResourceEditModal,
      (val) => {
        if (val === true) {
          attribute.value = state.clients.resource;
        } else {
          commit('initResource');
          if (params.id !== 'new') {
            dispatch('fetchClient', {
              id: params.id,
            });
          }
        }
      },
    );

    onMounted(async () => {
      try {
        const {
          data: { data, status },
        } = await DataService.get(`/storeviews`, {
          client_id: params['id'],
        });
        if (status === 'success') {
          clientStoreviews.value = data;
        }
      } catch ({ data }) {
        commit('setClientsError', data.error);
      }
    });

    return {
      addNew,
      visibleEditModal,
      modalTitle,
      onSave,
      attribute,
      allStoreviews,
    };
  },
};
</script>
