<template>
  <sdCards title="Client Product Attributes Group">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="addNew" :disabled="isSaving"
        >+ Add New Attribute Group</sdButton
      >
    </template>
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <AttributesTable />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>

  <a-modal
    v-model:visible="visibleEditModal"
    :title="modalTitle"
    @ok="onSave"
    width="80%"
    :dialog-style="{ top: '5px' }"
  >
    <BasicFormWrapper>
      <LocationFormWrapper>
        <a-form name="multi-form" layout="horizontal">
          <a-row :gutter="30">
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Attribute ID">
                <a-input v-model:value="attribute.attribute_id" />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Name">
                <a-input v-model:value="attribute.name" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :lg="12" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Restricted Storeviews">
                <a-checkbox-group v-model:value="attribute.store_views" :options="allStoreviews" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :xs="24">
              <div class="notification-box-single">
                <sdCards headless :bodyStyle="{ backgroundColor: '#F7F8FA', borderRadius: 10 }">
                  <div
                    :style="{
                      height: '50px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }"
                    class="notification-header"
                  >
                    <sdHeading class="notification-header__text" as="h4"> Product Modules </sdHeading>
                  </div>
                  <div class="notification-body">
                    <sdCards headless>
                      <nav>
                        <ul
                          :style="{
                            margin: 0,
                            padding: 0,
                          }"
                        >
                          <li :style="listStyle">
                            <div class="notification-list-single">
                              <sdHeading class="notification-list-single__title" as="h"> UK National Codes </sdHeading>
                            </div>
                            <a-switch v-model:checked="attribute.product_tool_1" />
                          </li>
                          <li :style="listStyle">
                            <div class="notification-list-single">
                              <sdHeading class="notification-list-single__title" as="h">
                                Alfardan Tire Finder
                              </sdHeading>
                            </div>
                            <a-switch v-model:checked="attribute.product_tool_2" />
                          </li>
                          <li :style="listStyle">
                            <div class="notification-list-single">
                              <sdHeading class="notification-list-single__title" as="h"> AlTayer Models </sdHeading>
                            </div>
                            <a-switch v-model:checked="attribute.product_tool_3" />
                          </li>
                          <li :style="listStyle">
                            <div class="notification-list-single">
                              <sdHeading class="notification-list-single__title" as="h"> AlTayer Models </sdHeading>
                            </div>
                            <a-switch v-model:checked="attribute.product_tool_4" />
                          </li>
                        </ul>
                      </nav>
                    </sdCards>
                  </div>
                </sdCards>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </LocationFormWrapper>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import { FileCardWrap, LocationFormWrapper } from './style';
import AttributesTable from './components/AttributesGroupTable.vue';
import { computed, ref, watch, onMounted } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';

export default {
  name: 'ClientLocation',
  components: { FileCardWrap, AttributesTable, BasicFormWrapper, LocationFormWrapper },
  setup() {
    const listStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
      padding: 0,
    };

    const { state, commit, dispatch } = useStore();

    const clientStoreviews = ref([]);
    const allStoreviews = computed(() => clientStoreviews.value.map((d) => d.code));

    const { params } = useRoute();

    const visibleEditModal = computed({
      get() {
        return state.clients.visibleAttributesGroupEditModal;
      },
      set(value) {
        commit('toggleAttributeGroupEditModal', value);
      },
    });

    const attribute = ref(() => {});
    const modalTitle = computed(() => {
      if (attribute.value.id > 0) {
        return `Update Attribute Group`;
      } else {
        return `Register a new Attribute Group`;
      }
    });

    const addNew = () => {
      commit('initAttributeGroup');
      commit('toggleAttributeGroupEditModal', true);
    };
    const onSave = async () => {
      commit('setAttributeGroup', attribute.value);
      commit('toggleAttributeGroupEditModal', false);
      if (attribute.value.id > 0) {
        await dispatch('updateAttributeGroup', { id: attribute.value.id });
      } else {
        await dispatch('createAttributeGroup');
      }
    };

    watch(
      () => state.clients.visibleAttributesGroupEditModal,
      (val) => {
        if (val === true) {
          attribute.value = state.clients.attributeGroup;
        } else {
          commit('initAttributeGroup');
          if (params.id !== 'new') {
            dispatch('fetchClient', {
              id: params.id,
            });
          }
        }
      },
    );

    // Getting storeviews by client id
    onMounted(async () => {
      try {
        const {
          data: { data, status },
        } = await DataService.get(`/storeviews`, {
          client_id: params['id'],
        });
        if (status === 'success') {
          clientStoreviews.value = data;
        }
      } catch ({ data }) {
        commit('setClientsError', data.error);
      }
    });

    return {
      addNew,
      visibleEditModal,
      modalTitle,
      onSave,
      attribute,
      allStoreviews,
      listStyle,
    };
  },
};
</script>
