<template>
  <sdPageHeader title="Client Details" />
  <Main>
    <ClientsDetailWrapper>
      <div v-if="isLoading" class="spin">
        <a-spin />
      </div>
      <a-row v-else class="justify-content-center" :gutter="25">
        <a-col class="trigger-col" :xxl="5" :xl="7" :lg="9" :xs="24">
          <sdButton
            v-if="responsive <= 991"
            type="link"
            class="mail-sidebar-trigger"
            :style="{ marginTop: 0 }"
            @click="toggleCollapsed"
          >
            <sdFeatherIcons :type="collapsed ? 'align-left' : 'align-right'" />
          </sdButton>

          <div v-if="responsive > 991" class="sidebar-card">
            <sdCards headless>
              <div class="note-sidebar-bottom">
                <NoteNav>
                  <ul>
                    <li v-for="item in navItems" :key="item.id">
                      <router-link :to="path + item.link">
                        <sdFeatherIcons :type="item.icon" size="18" />
                        <span class="nav-text">
                          <span>{{ item.text }}</span>
                        </span>
                      </router-link>
                    </li>
                  </ul>
                </NoteNav>
              </div>
            </sdCards>
          </div>
          <div v-else :class="collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'">
            <sdCards headless>
              <NoteNav>
                <ul>
                  <li v-for="item in navItems" :key="item.id">
                    <router-link :to="path + item.link">
                      <sdFeatherIcons :type="item.icon" size="18" />
                      <span class="nav-text">
                        <span>{{ item.text }}</span>
                      </span>
                    </router-link>
                  </li>
                </ul>
              </NoteNav>
            </sdCards>
          </div>
        </a-col>
        <a-col :xxl="19" :xl="17" :lg="15" :xs="24">
          <router-view></router-view>
        </a-col>
      </a-row>
    </ClientsDetailWrapper>
  </Main>
</template>

<script>
import { Main } from '@/view/styled';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import { ClientsDetailWrapper, NoteNav } from './style';

export default {
  name: 'ClientDetails',
  components: {
    Main,
    NoteNav,
    ClientsDetailWrapper,
  },
  setup() {
    const { state, dispatch, commit } = useStore();
    const isLoading = computed(() => state.clients.loading);

    const navItems = [
      {
        id: 'information',
        text: 'Information',
        link: '/information',
        icon: 'info',
      },
      {
        id: 'locations',
        text: 'Locations',
        link: '/locations',
        icon: 'map-pin',
      },
      {
        id: 'users',
        text: 'Users',
        link: '/users',
        icon: 'users',
      },
      {
        id: 'attributes',
        text: 'Product Attributes',
        link: '/attributes',
        icon: 'users',
      },
      {
        id: 'attributesGroup',
        text: 'Product Attributes Group',
        link: '/attributesGroup',
        icon: 'users',
      },
      {
        id: 'resources',
        text: 'Resources',
        link: '/resources',
        icon: 'users',
      },
    ];
    const responsive = ref(0);
    const collapsed = ref(false);
    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    const collapseSidebar = () => {
      collapsed.value = false;
    };

    const { params } = useRoute();
    const path = `/admin/omni/clients/${params.id}`;

    onMounted(() => {
      function updateSize() {
        const width = window.innerWidth;
        responsive.value = width;
      }
      window.addEventListener('resize', updateSize);
      updateSize();

      if (params.id !== 'new') {
        dispatch('fetchClient', {
          id: params.id,
        });
      } else {
        commit('setClient', {});
      }
    });

    return {
      isLoading,
      navItems,
      path,
      collapseSidebar,
      toggleCollapsed,
      collapsed,
      responsive,
    };
  },
};
</script>
