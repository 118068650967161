<template>
  <CardToolbox>
    <sdPageHeader title="All Clients">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" @click="addNew"> + Add New Client </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <ClientsTable />
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import ClientsTable from './Table';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox } from '../../styled';
import { useRouter } from 'vue-router';

const ClientsTableList = {
  name: 'ClientsTableList',
  components: { Main, CardToolbox, ClientsTable },
  setup() {
    const { dispatch } = useStore();
    const { push } = useRouter();

    const addNew = () => {
      push({
        name: 'omni-client-information',
        params: { id: 'new' },
      });
    };

    onMounted(() => {
      dispatch('fetchClients');
    });

    return {
      addNew,
    };
  },
};

export default ClientsTableList;
</script>
