<template>
  <sdCards title="Client Locations">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="addNew" :disabled="isSaving">+ Add New Location</sdButton>
    </template>
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <ClientLocationsTable />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>

  <a-modal
    v-model:visible="visibleEditModal"
    :title="modalTitle"
    @ok="onSave"
    width="80%"
    :dialog-style="{ top: '5px' }"
  >
    <BasicFormWrapper>
      <LocationFormWrapper>
        <a-form name="multi-form" layout="horizontal">
          <a-row :gutter="30">
            <a-col :lg="8" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Name">
                <a-input v-model:value="location.location_name" />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Phone">
                <a-input v-model:value="location.phone" />
              </a-form-item>
            </a-col>
            <a-col :lg="8" :md="12" :xs="24" class="mb-25">
              <a-form-item label="Brand">
                <a-input v-model:value="location.brand" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Order ID">
                <a-input type="number" v-model:value="location.location_order_id" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="VSF store ID">
                <a-input type="number" v-model:value="location.vsf_store_id" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Address Line 1">
                <a-input v-model:value="location.street" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Address Line 2">
                <a-input v-model:value="location.region" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :xs="8" class="mb-25">
              <a-form-item label="City">
                <a-input v-model:value="location.city" />
              </a-form-item>
            </a-col>
            <a-col :xs="8" class="mb-25">
              <a-form-item label="Postcode">
                <a-input v-model:value="location.postcode" />
              </a-form-item>
            </a-col>
            <a-col :xs="8" class="mb-25">
              <a-form-item label="Country">
                <a-select v-model:value="location.country">
                  <a-select-option v-for="{ name, code } in countries" :key="code" :value="code">
                    <span>{{ name }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :xs="6" class="mb-25">
              <a-form-item label="Collection">
                <a-checkbox :checked="!!location.collection" @change="onCheckboxChange($event, 'collection')" />
              </a-form-item>
            </a-col>
            <a-col :xs="6" class="mb-25">
              <a-form-item label="Delivery">
                <a-checkbox :checked="!!location.delivery" @change="onCheckboxChange($event, 'delivery')" />
              </a-form-item>
            </a-col>
            <a-col :xs="6" class="mb-25">
              <a-form-item label="Fitment">
                <a-checkbox :checked="!!location.fitment" @change="onCheckboxChange($event, 'fitment')" />
              </a-form-item>
            </a-col>
            <a-col :xs="6" class="mb-25">
              <a-form-item label="Is Hub?">
                <a-checkbox :checked="!!location.is_hub" @change="onCheckboxChange($event, 'is_hub')" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Latitude">
                <a-input v-model:value="location.latitude" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Longitude">
                <a-input v-model:value="location.longitude" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="Print Label">
                <a-checkbox :checked="!!location.print_label" @change="onCheckboxChange($event, 'print_label')" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="API Url">
                <a-input v-model:value="location.api_url" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="30">
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="API Token">
                <a-input v-model:value="location.api_token" />
              </a-form-item>
            </a-col>
            <a-col :md="12" :xs="24" class="mb-25">
              <a-form-item label="API User">
                <a-input v-model:value="location.api_user" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </LocationFormWrapper>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import { FileCardWrap, LocationFormWrapper } from './style';
import ClientLocationsTable from './components/LocationTable.vue';
import { computed, ref, watch } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import countries from '@/static/json/countries.json';

export default {
  name: 'ClientLocation',
  components: { FileCardWrap, ClientLocationsTable, BasicFormWrapper, LocationFormWrapper },
  setup() {
    const { state, commit, dispatch } = useStore();

    const visibleEditModal = computed({
      get() {
        return state.clients.visibleLocationEditModal;
      },
      set(value) {
        commit('toggleLocationEditModal', value);
      },
    });
    const location = ref(() => {});
    const modalTitle = computed(() => {
      if (location.value.id > 0) {
        return `Update location`;
      } else {
        return `Register a new location`;
      }
    });

    const addNew = () => {
      commit('initLocation');
      commit('toggleLocationEditModal', true);
    };
    const onSave = async () => {
      commit('setLocation', location.value);
      commit('toggleLocationEditModal', false);
      if (location.value.id > 0) {
        await dispatch('updateLocation', { id: location.value.id });
      } else {
        await dispatch('createLocation');
      }
    };
    const onCheckboxChange = (e, type) => {
      location.value[type] = e.target.checked ? 1 : 0;
    };

    watch(
      () => state.clients.visibleLocationEditModal,
      (val) => {
        if (val === true) {
          location.value = state.clients.location;
        } else {
          commit('initLocation');
        }
      },
    );

    return {
      addNew,
      visibleEditModal,
      modalTitle,
      onSave,
      location,
      onCheckboxChange,
      countries,
    };
  },
};
</script>
