<template>
  <CardToolbox>
    <sdPageHeader :title="meta.title">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="0" @click="toggleFilters"
          >Apply Filters</sdButton
        >
        <!-- <sdButton class="btn-add_new" size="default" type="primary" key="1">
          <router-link to="/omni/orders/create">+ Add New Order</router-link>
        </sdButton> -->
      </template>
    </sdPageHeader>
    <OrderFilters v-if="showFilters" />
  </CardToolbox>

  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <OrderListTable />
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import OrderListTable from './overview/OrderTable';
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox } from '../../styled';
import OrderFilters from './OrderFilters.vue';
import { useRoute } from 'vue-router';

const OrderList = {
  name: 'OrderList',
  components: { Main, CardToolbox, OrderListTable, OrderFilters },
  setup() {
    const { state, dispatch, commit } = useStore();
    const { meta } = useRoute();
    const filters = computed(() => state.orders.filters);

    const searchKey = computed(() => state.orders.searchKey);
    const onSearchChange = (sku) => {
      commit('setSearchKey', sku);
      dispatch('fetchOrders');
    };

    const showFilters = ref(false);
    const toggleFilters = () => {
      showFilters.value = !showFilters.value;
    };

    const currentStoreView = computed(() => state.auth.activeStoreView);
    watch(currentStoreView, () => {
      showFilters.value = false;
      commit('clearOrderFilters');
      dispatch('fetchOrders');
    });

    onMounted(() => {
      commit('setHasOrder', true);
      if (meta.title === 'Draft Orders') {
        commit('setOrderFilters', { ...filters.value, status: 'Draft' });
      } else {
        commit('clearOrderFilters');
      }
      dispatch('fetchOrders');
    });

    return {
      searchKey,
      onSearchChange,
      showFilters,
      toggleFilters,
      meta,
    };
  },
};

export default OrderList;
</script>
