<template>
  <!-- <sdPageHeader title="" /> -->
  <Main>
    <OrderWrapper>
      <div v-if="isLoading" class="spin">
        <a-spin />
      </div>
      <a-row v-else class="justify-content-center" :gutter="25">
        <div v-if="responsive > 991" class="sidebar-card">
          <sdCards headless class="mb-25">
            <div class="note-sidebar-bottom">
              <NoteNav>
                <ul>
                  <li v-for="item in navItems" :key="item.id">
                    <router-link :to="path + item.link" v-if="hasRefunds(item.id)">
                      <sdFeatherIcons :type="item.icon" size="18" />
                      <span class="nav-text">
                        <span>{{ item.text }}</span>
                      </span>
                    </router-link>
                  </li>
                </ul>
              </NoteNav>
            </div>
          </sdCards>
          <sdButton type="primary" @click="visibleContactModal = true" block>
            <sdFeatherIcons type="send" :style="{ marginRight: '5px' }" /> Contact Customer</sdButton
          >
        </div>
        <div v-else :class="collapsed ? 'sidebar-card note-sideabr show' : 'sidebar-card note-sideabr hide'">
          <sdCards headless class="mb-25">
            <NoteNav>
              <ul>
                <li v-for="item in navItems" :key="item.id">
                  <router-link :to="path + item.link">
                    <sdFeatherIcons :type="item.icon" size="18" />
                    <span class="nav-text">
                      <span>{{ item.text }}</span>
                    </span>
                  </router-link>
                </li>
              </ul>
            </NoteNav>
          </sdCards>
          <sdButton type="primary" @click="visibleContactModal = true" block>
            <sdFeatherIcons type="send" :style="{ marginRight: '5px' }" /> Contact Customer</sdButton
          >
        </div>
        <a-col :xxl="24" :xl="24" :lg="24" :xs="24" class="order-body">
          <router-view></router-view>
        </a-col>
        <div v-if="responsive > 991" class="sidebar-card--right">
          <sdCards headless :style="{ overflowY: 'auto' }">
            <div class="status-bar">
              <a-row class="m-15">
                <a-col :xs="24" class="header"> Customer </a-col>
                <a-col :xs="24" class="content">
                  {{ order?.customer_firstname + ' ' + order?.customer_lastname }}</a-col
                >
                <a-col :xs="24" class="content"> Type: {{ customerGroup }} </a-col>
              </a-row>
              <a-row class="m-15">
                <a-col :xs="24" class="header"> Contact Information </a-col>
                <a-col :xs="24" class="content"> {{ order?.customer_email }} </a-col>
              </a-row>
              <a-row class="m-15">
                <a-col :xs="24" class="header"> SHIPPING INFORMATION </a-col>
                <a-col :xs="24" class="content">{{ shippingAddress.firstname + ' ' + shippingAddress.lastname }}</a-col>
                <a-col :xs="24" class="content">{{
                  shippingAddress.street ? shippingAddress.street.join(' ') : ''
                }}</a-col>
                <a-col :xs="24" class="content" v-if="shippingAddress.city">{{ shippingAddress.city }}</a-col>
                <a-col :xs="24" class="content" v-if="shippingAddress.region">{{ shippingAddress.region }}</a-col>
                <a-col :xs="24" class="content" v-if="shippingAddress.country_id">{{
                  shippingAddress.country_id
                }}</a-col>
                <a-col :xs="24" class="content" v-if="shippingAddress.postcode">{{ shippingAddress.postcode }}</a-col>
                <a-col :xs="24" class="content" v-if="shippingAddress.telephone">{{ shippingAddress.telephone }}</a-col>
              </a-row>
              <a-row class="m-15">
                <a-col :xs="24" class="header"> BILLING INFORMATION </a-col>
                <a-col :xs="24" class="content">{{
                  order.billing_address.firstname + ' ' + order.billing_address.lastname
                }}</a-col>
                <a-col :xs="24" class="content">{{
                  order.billing_address.street ? order.billing_address.street.join(' ') : ''
                }}</a-col>
                <a-col :xs="24" class="content" v-if="order.billing_address.city">{{
                  order.billing_address.city
                }}</a-col>
                <a-col :xs="24" class="content" v-if="order.billing_address.region">{{
                  order.billing_address.region
                }}</a-col>
                <a-col :xs="24" class="content" v-if="order.billing_address.country_id">{{
                  order.billing_address.country_id
                }}</a-col>
                <a-col :xs="24" class="content" v-if="order.billing_address.postcode">{{
                  order.billing_address.postcode
                }}</a-col>
                <a-col :xs="24" class="content" v-if="order.billing_address.telephone">{{
                  order.billing_address.telephone
                }}</a-col>
              </a-row>
              <a-row class="m-15">
                <a-col :xs="24" class="header"> PAYMENT INFORMATION </a-col>
                <a-col :xs="24" class="content">{{ paymentInformation }}</a-col>
              </a-row>
            </div>
          </sdCards>
        </div>
      </a-row>
    </OrderWrapper>
  </Main>

  <a-modal
    v-model:visible="visibleContactModal"
    title="Contact customer"
    width="40%"
    @ok="reviewEmail"
    okText="Review Email"
    :confirm-loading="sendingEmail"
  >
    <BasicFormWrapper>
      <a-form layout="vertical">
        <a-form-item label="Custom message" :style="{ marginBottom: '0' }">
          <a-textarea
            placeholder="This message can be edited in notifications."
            v-model:value="customMessage"
            :auto-size="{ minRows: 5 }"
            :disabled="saving"
          />
        </a-form-item>
      </a-form>
    </BasicFormWrapper>
  </a-modal>
</template>

<script>
import { Main } from '../../../../styled';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted, computed, ref } from 'vue';
import { OrderWrapper, NoteNav } from './style';
import { DataService } from '@/config/dataService/dataService';
import { Notification } from 'ant-design-vue';

export default {
  name: 'OrderDetails',
  components: {
    Main,
    NoteNav,
    OrderWrapper,
  },
  setup() {
    const { state, dispatch, commit } = useStore();
    const isLoading = computed(() => state.orders.loading);
    const order = computed(() => state.orders.order);
    const userRoles = computed(() => state.auth.user.scopes.map((scope) => scope.name));

    const hasRole = (role) => userRoles.value.includes(role);
    const hasRefunds = (role) => {
      if (role !== 'refunds') return true;
      else return hasRole('refund');
    };
    // const { push } = useRouter();

    // if (!order.value?.entity_id) {
    //   push({ name: 'omni-orders-list', meta: { title: '' } });
    // }
    const user = computed(() => state.auth.user);
    const customerGroup = order.value.customer_is_guest === 0 ? 'LOGGED IN' : 'NOT LOGGED IN';
    const shippingAddress = computed(() => {
      const shippingAssignments = state.orders.order?.extension_attributes?.shipping_assignments;

      if (shippingAssignments && shippingAssignments.length) {
        return shippingAssignments[0].shipping.address;
      } else {
        return {};
      }
    });

    const paymentInformation = computed(() => {
      // let result = {};
      // const infoKeys = ['paymmentID', 'paymentID2', 'method_title'];

      // state.orders.order?.extension_attributes?.payment_additional_info.map(({ key, value }) => {
      //   if (infoKeys.includes(key)) {
      //     result[key] = value;
      //   }
      // });

      // return `${result['method_title']}-${result['paymmentID']}-${result['paymentID2']}`;

      return state.orders.order?.extension_attributes?.payment_additional_info?.map((info) => info?.value).join(', ');
    });

    const navItems = [
      {
        id: 'information',
        text: 'Information',
        link: '/information',
        icon: 'user',
      },
      {
        id: 'invoices',
        text: 'Invoices',
        link: '/invoices',
        icon: 'info',
      },
      {
        id: 'refunds',
        text: 'Refunds',
        link: '/refunds',
        icon: 'eye',
      },
      {
        id: 'fulfilments',
        text: 'Fulfilments',
        link: '/shipments',
        icon: 'gift',
      },
      {
        id: 'transactions',
        text: 'Transactions',
        link: '/transactions',
        icon: 'shopping-cart',
      },
      {
        id: 'order_history',
        text: 'Order History',
        link: '/order-history',
        icon: 'layers',
      },
    ];
    const responsive = ref(0);
    const collapsed = ref(false);
    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    const collapseSidebar = () => {
      collapsed.value = false;
    };

    const { params } = useRoute();
    const path = `/omni/orders/${params.id}`;

    const visibleContactModal = ref(false);
    const customMessage = ref('');
    const sendingEmail = ref(false);
    const reviewEmail = async () => {
      sendingEmail.value = true;
      const {
        data: { status },
      } = await DataService.post('send-email', {
        email: order.value.customer_email,
        name: order.value.customer_firstname + ' ' + order.value.customer_lastname,
        message: customMessage.value,
      });

      if (status === 'success') {
        customMessage.value = '';
        Notification['success']({
          message: 'Email was sent successfully.',
          description: 'Check your inbox.',
        });
      }

      visibleContactModal.value = false;
      sendingEmail.value = false;
    };

    const fetchOrder = async () => {
      if (params.id) {
        await dispatch('fetchOrder', {
          entityId: params.id,
        });
      }
    };

    onMounted(async () => {
      function updateSize() {
        const width = window.innerWidth;
        responsive.value = width;
      }
      commit('setHasOrder', true);
      window.addEventListener('resize', updateSize);
      updateSize();
      await fetchOrder();
    });

    return {
      order,
      isLoading,
      navItems,
      path,
      collapseSidebar,
      toggleCollapsed,
      collapsed,
      responsive,
      visibleContactModal,
      customMessage,
      reviewEmail,
      sendingEmail,
      user,
      customerGroup,
      shippingAddress,
      paymentInformation,
      hasRefunds,
    };
  },
};
</script>
