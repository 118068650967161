<template>
  <a-row>
    <a-col :xxl="16" :xl="9" :lg="12" :md="8" :xs="24">
      <Aside>
        <div class="auth-side-content">
          <Content>
            <img
              :style="{ width: '280px', zIndex: 1, position: 'relative' }"
              :src="require('@/static/img/logo-light.png')"
              alt=""
            />
          </Content>
        </div>
      </Aside>
    </a-col>

    <a-col :xxl="8" :xl="15" :lg="12" :md="16" :xs="24">
      <router-view></router-view>
    </a-col>
  </a-row>
</template>
<script>
import { Aside, Content } from './style';

const AuthLayout = {
  name: 'AuthLayout',
  components: { Aside, Content },
};

export default AuthLayout;
</script>
