<template>
  <FullHeightSdCardWrapper>
    <sdPageHeader title="Payment & Shipping Method" />
    <a-row justify="center" :gutter="25">
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards v-if="order.payment" title="Payment Information">
              <p>{{ order.payment.additional_information[0] }}</p>
              <p>The order was placed using {{ order.order_currency_code }}</p>
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
      <a-col :md="12" :xs="24">
        <Suspense>
          <template #default>
            <sdCards title="Shipping Information">
              <p>
                <b>{{ order.shipping_description }}</b>
              </p>
              <p>
                Total Shipping Charges: {{ currencyFromCode(order.order_currency_code) }} {{ order.shipping_amount }}
              </p>
              <!-- <div>
                <a-checkbox :check="createShipment" @change="e => (createShipment = e.target.checked)"
                  >Create Shipment</a-checkbox
                >
              </div> -->
            </sdCards>
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>
  </FullHeightSdCardWrapper>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { FullHeightSdCardWrapper } from '@/view/styled';
import { currencyFromCode } from '@/utility/filter';

export default {
  components: {
    FullHeightSdCardWrapper,
  },
  setup() {
    const { state } = useStore();
    const order = computed(() => state.orders.order);
    // const createShipment = ref(false);

    return {
      order,
      currencyFromCode,
      // createShipment,
    };
  },
};
</script>
