<template>
  <sdCards title="Client Users">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="addNew" :disabled="isSaving">+ Add New User</sdButton>
    </template>
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <ClientUsersTable />
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>

  <a-modal v-model:visible="visibleEditModal" :title="modalTitle" @ok="onSave" width="80%">
    <BasicFormWrapper>
      <a-form name="multi-form" layout="horizontal">
        <a-row :gutter="30">
          <a-col :md="12" :xs="24" class="mb-25">
            <a-form-item label="Name">
              <a-input v-model:value="user.name" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :xs="24" class="mb-25">
            <a-form-item label="E-mail">
              <a-input v-model:value="user.email" type="email" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="30">
          <a-col :md="12" :xs="24" class="mb-25">
            <a-form-item label="Password">
              <a-input v-model:value="user.password" type="password" />
            </a-form-item>
          </a-col>
          <a-col :md="12" :xs="24" class="mb-25">
            <a-form-item label="Is Admin?">
              <a-checkbox :checked="!!user.is_admin" @change="onCheckboxChange" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-form-item label="Roles">
            <a-checkbox-group v-model:value="user.roles" :options="allRoles" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="Scopes">
            <a-checkbox-group v-model:value="user.scopes" :options="allScopes" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="Storeviews">
            <a-checkbox-group v-model:value="user.store_views" :options="allStoreviews" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="Locations">
            <a-checkbox-group v-model:value="user.locations" :options="allLocations" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="Email Receiving Option">
            <a-checkbox :checked="!!user.email_only" @change="onEmailOptionChange" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="MFA(Multi-factor Authentication)">
            <a-checkbox :checked="!!user.mfa" @change="onMFAChange" />
          </a-form-item>
        </a-row>
      </a-form>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import { FileCardWrap } from './style';
import ClientUsersTable from './components/UserTable.vue';
import { computed, ref, watch, onMounted } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { DataService } from '@/config/dataService/dataService';
import { useRoute } from 'vue-router';

export default {
  name: 'ClientUser',
  components: { FileCardWrap, ClientUsersTable, BasicFormWrapper },
  setup() {
    const { state, commit, dispatch } = useStore();

    const visibleEditModal = computed({
      get() {
        return state.clients.visibleUserEditModal;
      },
      set(value) {
        commit('toggleUserEditModal', value);
      },
    });
    const user = ref(() => {});
    const modalTitle = computed(() => {
      if (user.value.id > 0) {
        return `Update User`;
      } else {
        return `Register a new user`;
      }
    });

    const allScopes = computed(() => state.clients.allScopes.map((scope) => scope.name));
    const allRoles = computed(() => state.clients.allRoles.map((role) => role.name));
    const clientStoreviews = ref([]);

    const allStoreviews = computed(() => clientStoreviews.value.map((d) => d.code));
    const allLocations = computed(() => state.clients.client.locations.map((location) => location.location_name));

    const addNew = () => {
      commit('initUser');
      commit('toggleUserEditModal', true);
    };

    const onSave = async () => {
      commit('setUser', user.value);
      commit('toggleUserEditModal', false);
      const storeviews = user.value.store_views.map(
        (storeview) => clientStoreviews.value.find((s) => s.code === storeview)['id'],
      );

      if (user.value.id > 0) {
        await dispatch('updateUser', { id: user.value.id, storeviews });
      } else {
        await dispatch('createUser', { storeviews });
      }
    };
    const onCheckboxChange = (e) => {
      user.value['is_admin'] = e.target.checked ? 1 : 0;
    };
    const onEmailOptionChange = (e) => {
      user.value['email_only'] = e.target.checked ? 1 : 0;
    };

    const onMFAChange = (e) => {
      user.value['mfa'] = e.target.checked ? 1 : 0;
    };

    watch(
      () => state.clients.visibleUserEditModal,
      (val) => {
        if (val === true) {
          user.value = state.clients.user;
        } else {
          commit('initUser');
        }
      },
    );

    // Getting storeviews by client id
    const { params } = useRoute();
    onMounted(async () => {
      try {
        const {
          data: { data, status },
        } = await DataService.get(`/storeviews`, {
          client_id: params['id'],
        });
        if (status === 'success') {
          clientStoreviews.value = data;
        }
      } catch ({ data }) {
        commit('setClientsError', data.error);
      }
    });

    return {
      addNew,
      visibleEditModal,
      modalTitle,
      onSave,
      user,
      onCheckboxChange,
      allScopes,
      allRoles,
      allStoreviews,
      allLocations,
      onEmailOptionChange,
      onMFAChange,
    };
  },
};
</script>
