<template>
  <sdPageHeader title="New Order">
    <template #buttons>
      <sdButton class="btn-add_new" size="default" type="primary" @click="onSave"> Save </sdButton>
    </template>
  </sdPageHeader>
  <Main>
    <a-row v-if="saving" justify="center">
      <a-col>
        <a-spin />
      </a-col>
    </a-row>
    <div v-else>
      <sdCards title="Products">
        <template #button>
          <sdButton class="btn-add_new" size="default" type="warning" @click="addNewProduct"> Add </sdButton>
        </template>
        <BasicFormWrapper>
          <a-form name="multi-form" layout="horizontal">
            <div v-for="(order, index) in products" :key="index">
              <a-row :gutter="30" align="middle" justify="end">
                <a-col class="mr-8"><b>Total</b></a-col>
              </a-row>
              <a-row :gutter="30" align="middle">
                <a-col :xs="4">
                  <a-form-item label="Item SKU / Part Number">
                    <a-input v-model:value="products[index]['sku']" />
                  </a-form-item>
                </a-col>
                <a-col :xs="4">
                  <a-form-item label="Item name">
                    <a-input v-model:value="products[index]['name']" />
                  </a-form-item>
                </a-col>
                <a-col :xs="4">
                  <a-form-item label="Tax Rate (%)">
                    <a-input v-model:value="products[index]['tax']" />
                  </a-form-item>
                </a-col>
                <a-col :xs="4">
                  <a-form-item label="Price (Pound)">
                    <a-input type="number" placeholder="0.00" step="0.01" v-model:value="products[index]['price']" />
                  </a-form-item>
                </a-col>
                <a-col :xs="4">
                  <a-form-item label="Quantity">
                    <a-input type="number" v-model:value="products[index]['qty_ordered']" />
                  </a-form-item>
                </a-col>
                <a-col class="total-wrapper" :xs="4">
                  <span class="total-value">{{
                    (products[index]['qty_ordered'] * products[index]['price'] * 100) / 100
                  }}</span>
                  <sdFeatherIcons class="close-button" type="x-circle" size="20" @click="removeProduct(index)" />
                </a-col>
              </a-row>
              <a-row class="requires-shipping-row">
                <a-col>
                  <a-checkbox v-model:checked="products[index]['is_virtual']"> Item requires shipping </a-checkbox>
                </a-col>
              </a-row>
              <a-divider />
            </div>
          </a-form>
        </BasicFormWrapper>
      </sdCards>

      <a-row :gutter="25">
        <a-col :md="12" :xs="24">
          <sdCards title="Customer Details">
            <BasicFormWrapper>
              <a-form name="customer-details" layout="horizontal">
                <a-row :gutter="30" align="middle">
                  <a-col :md="8" :xs="24">
                    <a-form-item label="First Name">
                      <a-input v-model:value="customer.firstName" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Last Name">
                      <a-input v-model:value="customer.lastName" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Email">
                      <a-input type="email" v-model:value="customer.email" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </BasicFormWrapper>
          </sdCards>

          <sdCards title="Shipping / Fulfilment">
            <BasicFormWrapper>
              <a-form name="customer-details" layout="horizontal">
                <a-row :gutter="30" align="middle">
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Delivery is available:">
                      <a-switch class="mr-10" v-model:checked="shippingData.delivery.available" /><span>Yes / No</span>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Cost:">
                      <a-input v-model:value="shippingData.delivery.amount.cost" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Tax:">
                      <a-input v-model:value="shippingData.delivery.amount.tax" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30" align="middle">
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Click & Collect available:">
                      <a-switch class="mr-10" v-model:checked="shippingData.collection.available" /><span
                        >Yes / No</span
                      >
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Cost:">
                      <a-input v-model:value="shippingData.collection.amount.cost" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Tax:">
                      <a-input v-model:value="shippingData.collection.amount.tax" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30" align="middle">
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Fitment is available:">
                      <a-switch class="mr-10" v-model:checked="shippingData.fitment.available" /><span>Yes / No</span>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Cost:">
                      <a-input v-model:value="shippingData.fitment.amount.cost" />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :xs="24">
                    <a-form-item label="Tax:">
                      <a-input v-model:value="shippingData.fitment.amount.tax" />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </BasicFormWrapper>
          </sdCards>
          <sdCards title="Payment Methods">
            <BasicFormWrapper>
              <a-form name="customer-details" layout="horizontal">
                <a-row :gutter="30" align="middle" v-for="key in Object.keys(paymentMethods)" :key="key">
                  <a-col :md="24" :xs="24">
                    <a-form-item label="">
                      <a-checkbox v-model:checked="paymentMethods[key]">
                        {{ key }}
                      </a-checkbox>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </BasicFormWrapper>
          </sdCards>
        </a-col>
        <a-col :md="12" :xs="24">
          <sdCards title="Order Totals" class="order-total">
            <a-row justify="space-between" class="mb-20">
              <a-col><b>Subtotal</b></a-col>
              <a-col>{{ orderTotal.subtotal }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col>Shipping & Handling</a-col>
              <a-col>{{ orderTotal.shippingHandling }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col><b>Grand Total Excl. Tax</b></a-col>
              <a-col>{{ orderTotal.exclTax }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col>Tax</a-col>
              <a-col>{{ orderTotal.tax }}</a-col>
            </a-row>
            <a-row justify="space-between" class="mb-20">
              <a-col><b>Grand Total Incl. Tax</b></a-col>
              <a-col>{{ orderTotal.inclTax }}</a-col>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>
    </div>
  </Main>
</template>

<script>
import { BasicFormWrapper, Main } from '@/view/styled';
import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';

const initialProductData = {
  sku: '',
  name: '',
  price: 0,
  qty_ordered: 0,
  tax: 0,
  is_virtual: false,
};

export default {
  name: 'OrderCreate',
  components: { BasicFormWrapper, Main },
  setup() {
    const { dispatch, state } = useStore();
    const removeProduct = (index) => {
      products.value.splice(index, 1);
    };

    const addNewProduct = () => {
      products.value = [
        ...products.value,
        {
          sku: '',
          name: '',
          price: 0,
          qty_ordered: 0,
          tax: 0,
          is_virtual: false,
        },
      ];
    };

    const products = ref([initialProductData]);
    let customer = reactive({
      firstName: '',
      lastName: '',
      email: '',
    });

    const availableMethods = computed(() => {
      const user = state.auth.user;
      const activeStoreview = state.storeviews.storeview;
      let storeview = {};
      if (activeStoreview.id === -1) {
        storeview = user.store_views[0];
      } else {
        storeview = user.store_views.find((s) => s.id === activeStoreview.id);
      }
      let result = {
        paypal: 1,
        stripe: 1,
        cybersource: 1,
        checkoutcom: 1,
      };
      Object.keys(result).map((key) => {
        if (!storeview[key]['status']) {
          delete result[key];
        }
      });
      return result;
    });

    const paymentMethods = reactive({ ...availableMethods.value });

    let shippingData = reactive({
      delivery: {
        available: 1,
        amount: {
          cost: 0,
          tax: 0,
        },
      },
      collection: {
        available: 1,
        amount: {
          cost: 0,
          tax: 0,
        },
        location_whitelist: 22,
      },
      fitment: {
        available: 1,
        amount: {
          cost: 0,
          tax: 0,
        },
        location_whitelist: 22,
      },
    });

    const orderTotal = computed(() => {
      let result = {
        subtotal: 0,
        shippingHandling: 0,
        exclTax: 0,
        tax: 0,
        inclTax: 0,
        qtyOrdered: 1,
      };
      payloadProducts.value.map((product) => {
        result.exclTax += Math.round(product.row_total * 100) / 100;
        result.tax += Math.round((product.row_total_incl_tax - product.row_total) * 100) / 100;
        result.inclTax += Math.round(product.row_total_incl_tax * 100) / 100;
        result.subtotal += Math.round(product.row_total_incl_tax * 100) / 100;
        result.qtyOrdered += product.qty_ordered;
      });

      return result;
    });

    const payloadProducts = computed(() => {
      return products.value
        .filter((p) => p.sku)
        .map((p) => {
          const { name, is_virtual, sku, price, qty_ordered, tax } = p;
          const row_total = Math.round(+price * +qty_ordered * 100) / 100;
          const price_incl_tax = +(Number(price) + Number((price * tax) / 100));
          const row_total_incl_tax = Math.round(price_incl_tax * +qty_ordered * 100) / 100;
          return {
            name,
            is_virtual: is_virtual ? 1 : 0,
            sku,
            qty_ordered: +qty_ordered,
            row_total: +row_total,
            row_total_incl_tax,
            price: +price,
            price_incl_tax,
          };
        });
    });

    const saving = computed(() => state.orders.saving);
    const onSave = async () => {
      const items = payloadProducts.value.map((product, index) => ({
        discount_amount: 0,
        discount_percent: 0,
        free_shipping: 0,
        is_virtual: product.is_virtual,
        name: product.name,
        original_price: 100,
        price: product.price,
        price_incl_tax: product.price_incl_tax,
        product_id: index + 1,
        product_type: 'simple',
        qty_ordered: product.qty_ordered,
        row_total: product.row_total,
        row_total_incl_tax: product.row_total_incl_tax,
        sku: product.sku,
      }));

      // const paymentMethodsString = Object.keys(paymentMethods)
      //   .map(key => `${key}:${paymentMethods[key]}`)
      //   .join(',');

      const payload = {
        customer_email: customer.email,
        customer_firstname: customer.firstName,
        customer_lastname: customer.lastName,
        customer_group_id: 0,
        customer_is_guest: 1,
        discount_amount: 0,
        grand_total: orderTotal.value.subtotal,
        is_virtual: 0,
        order_currency_code: 'GBP',
        shipping_amount: 1,
        shipping_description: 'Flat Rate - Fixed',
        state: 'holded',
        status: 'Draft',
        store_currency_code: 'GBP',
        store_id: 1,
        subtotal: orderTotal.value.subtotal,
        subtotal_incl_tax: orderTotal.value.inclTax,
        tax_amount: orderTotal.value.tax,
        total_item_count: payloadProducts.value.length,
        total_qty_ordered: payloadProducts.value.reduce((sum, p) => (sum += p.qty_ordered), 0),
        weight: 1,
        items,
        billing_address: {
          address_type: 'billing',
          city: 'unknown',
          company: 'unknown',
          country_id: 'unknown',
          email: customer.email,
          firstname: 'Tom',
          lastname: 'Brown',
          postcode: 'unknown',
          region: 'unknown',
          region_code: 'unknown',
          street: ['unknown'],
          telephone: 'unknown',
        },
        payment: {
          method: 'cnpayment',
        },
        extension_attributes: {
          shipping_assignments: [
            {
              shipping: {
                address: {
                  address_type: 'shipping',
                  city: 'unknown',
                  company: 'unknown',
                  country_id: 'unknown',
                  email: customer.email,
                  firstname: 'Tom',
                  lastname: 'Brown',
                  postcode: 'unknown',
                  region: 'unknown',
                  region_code: '1',
                  street: ['unknown'],
                  telephone: 'unknown',
                },
                method: 'flatrate_flatrate',
              },
            },
          ],
          location_id: 'location_id123-post',
          delivery_method: 'delivery_method123-Post',
          // fitting_price_total: 'fitting_price_total123-Post',
          vehicle_order: 'vehicle_order123',
          quote_delivery_available: shippingData.delivery.available,
          quote_delivery_cost: shippingData.delivery.amount.cost,
          quote_delivery_cost_tax: shippingData.delivery.amount.tax,
          quote_collection_available: shippingData.collection.available,
          quote_collect_cost: shippingData.collection.amount.cost,
          quote_collect_cost_tax: shippingData.collection.amount.tax,
          quote_fitment_available: shippingData.fitment.available,
          quote_fitment_cost: shippingData.fitment.amount.cost,
          quote_fitment_cost_tax: shippingData.fitment.amount.tax,
          fitting_price_total: JSON.stringify(paymentMethods),
        },
      };

      await dispatch('createOrder', payload);

      // products.value = [{ sku: '', name: '', price: 0, qty_ordered: 0, tax: 0, is_virtual: false }];
      // customer.firstName = '';
      // customer.lastName = '';
      // customer.email = '';
    };

    return {
      removeProduct,
      addNewProduct,
      products,
      onSave,
      customer,
      orderTotal,
      saving,
      shippingData,
      paymentMethods,
    };
  },
};
</script>

<style lang="scss">
.total-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close-button {
    cursor: pointer;
  }

  .total-value {
    font-weight: bold;
    margin-right: 30px;
  }
}

.requires-shipping-row {
  margin-top: 10px;
}
</style>
