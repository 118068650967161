<template>
  <OrderFiltersWrapper>
    <BasicFormWrapper>
      <a-form name="multi-form" layout="vertical">
        <a-row :gutter="30">
          <a-col :lg="24" :sm="24" :xs="24">
            <FilterDiv>
              <span class="title">Filters: </span>
              <span v-if="isShow && filterData && Object.keys(filterData).length > 0">
                <span class="m-20" v-for="(key, index) in Object.keys(filterData)" :key="index">
                  <span>{{ `${key}: ${filterData[key]}` }}</span>
                  <i class="fa fa-times" @click="removeFilter(key)" />
                </span>
              </span>
            </FilterDiv>
          </a-col>
          <a-col :md="6" :xs="24">
            <a-form-item label="By ID">
              <a-input v-model:value="formState.increment_id" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
            <a-form-item label="By Customer Email">
              <a-input v-model:value="formState.customer_email" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
            <a-form-item label="By Created Date">
              <div class="sub-form-item mb-20">
                <span>from</span>
                <a-date-picker
                  :value="formState.created_at.from"
                  @change="(date, dateString) => (formState.created_at.from = dateString)"
                />
              </div>
              <div class="sub-form-item">
                <span>to</span>
                <a-date-picker
                  :value="formState.created_at.to"
                  @change="(date, dateString) => (formState.created_at.to = dateString)"
                />
              </div>
            </a-form-item>
          </a-col>
          <a-col :md="6" :xs="24">
            <a-form-item label="By Status">
              <a-select v-model:value="formState.status" :style="{ height: '32px !important' }">
                <a-select-option value="complete">complete</a-select-option>
                <a-select-option value="closed">closed</a-select-option>
                <a-select-option value="Processing">Processing</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="30">
          <a-col :md="6" :xs="24">
            <a-form-item label="By Location">
              <a-select v-model:value="formState.location_id" :style="{ height: '32px !important' }">
                <a-select-option key="placeholder" value=""></a-select-option>
                <a-select-option v-for="{ label, value } in locations" :key="value" :value="value">{{
                  label
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row justify="end" :gutter="8">
          <a-col flex>
            <sdButton type="primary" :raised="true" @click="clearFilters"> Clear Filters </sdButton>
          </a-col>
          <a-col flex>
            <sdButton type="primary" :raised="true" @click="applyFilters" :disable="isSaving">
              {{ isSaving ? 'applying...' : 'Search' }}
            </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </BasicFormWrapper>
  </OrderFiltersWrapper>
</template>

<script>
import { BasicFormWrapper } from '@/view/styled';
import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { OrderFiltersWrapper, FilterDiv } from './style';
import { initialFilters } from '@/vuex/modules/orders/mutations';

export default {
  name: 'OrderFilters',
  components: { BasicFormWrapper, OrderFiltersWrapper, FilterDiv },
  setup() {
    const { state, commit, dispatch } = useStore();
    const filters = computed(() => state.orders.filters);
    const formState = ref(filters);
    const isSaving = computed(() => state.orders.saving);
    const locations = computed(() =>
      state.auth.user.locations.map(({ location_name: label, location_order_id: value }) => ({
        label,
        value,
      })),
    );
    const filterData = computed(() => state.omni.filterData1);
    const isShow = computed(() => state.omni.isShow);

    onMounted(() => {
      const decodedStringBtoA = 'SGVsbG8gV29ybGQh';
      console.log(atob(decodedStringBtoA));
      commit('setOrderFilters', initialFilters);
    });

    const applyFilters = async () => {
      commit('setShow', true);
      commit('setOrderFilters', formState.value);
      await dispatch('fetchOrders');
    };

    const clearFilters = () => {
      commit('setShow', false);
      commit('clearOrderFilters');
    };

    const makeFilters = (newValue) => {
      const data = {};
      let labels = [];
      Object.keys(newValue).map((key) => {
        if (newValue[key])
          switch (key) {
            case 'created_at':
              // if (newValue[key]?.from) {
              //   data['price_from'] = newValue[key].from;
              //   data.push({ key: 'price_from', value: newValue[key].from });
              // }
              // if (newValue[key]?.to) {
              //   data.push({ key: 'price_to', value: newValue[key].to });
              // }
              // break;
              break;
            case 'location_id':
              labels = locations.value.filter((option) => option.value === newValue[key]);
              if (labels?.length) {
                data[key] = labels[0]?.label;
              }
              break;
            case 'status':
              data[key] = newValue[key];
              break;
            case 'quantity':
              break;
            default:
              data[key] = newValue[key];
          }
      });

      commit('setFiltersData1', data);
    };

    const removeFilter = (key) => {
      formState.value[key] = '';
      applyFilters();
    };

    onMounted(() => {
      commit('setShow', false);
      commit('setFiltersData1', {});
    });

    watch(formState, makeFilters, { deep: true });

    return {
      formState,
      isSaving,
      applyFilters,
      clearFilters,
      locations,
      filterData,
      removeFilter,
      isShow,
    };
  },
};
</script>
