<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 5 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '@/view/styled';
import { computed } from 'vue';
import { useStore } from 'vuex';
import countries from '@/static/json/countries.json';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '90px',
  },
  {
    title: 'Name',
    dataIndex: 'location_name',
    key: 'location_name',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Order ID',
    dataIndex: 'location_order_id',
    key: 'location_order_id',
  },
  {
    title: 'VSF Store ID',
    dataIndex: 'vsf_store_id',
    key: 'vsf_store_id',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
  },
  {
    title: 'Collection',
    dataIndex: 'collection',
    key: 'collection',
  },
  {
    title: 'Delivery',
    dataIndex: 'delivery',
    key: 'delivery',
  },
  {
    title: 'Fitment',
    dataIndex: 'fitment',
    key: 'fitment',
  },
  {
    title: 'Is Hub?',
    dataIndex: 'is_hub',
    key: 'is_hub',
  },
  {
    title: 'Latitude',
    dataIndex: 'latitude',
    key: 'latitude',
  },
  {
    title: 'Longitude',
    dataIndex: 'longitude',
    key: 'longitude',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const clientLocationsTable = {
  name: 'ClientLocationsTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const locations = computed(() => state.clients.client.locations);
    const isLoading = computed(() => state.clients.loading);

    const onEdit = (id) => {
      const location = locations.value ? locations.value.find((location) => location.id === id) : null;
      if (location) {
        commit('setLocation', location);
      } else {
        commit('initLocation');
      }
      commit('toggleLocationEditModal', true);
    };

    const tableData = computed(() =>
      locations.value
        ? locations.value.map((location) => {
            const {
              id,
              brand,
              collection,
              delivery,
              fitment,
              is_hub,
              latitude,
              location_name,
              location_order_id,
              longitude,
              phone,
              vsf_store_id,
              region,
              city,
              street,
              postcode,
              country,
            } = location;

            const getAddress = () => {
              const countryObject = countries.find((c) => c.code === country);
              let result = '';
              result += region ? region + ',' : '';
              result += postcode ? postcode + ',' : '';
              result += street ? street + ',' : '';
              result += city ? city + ',' : '';
              result += countryObject ? countryObject.name + ',' : '';
              return result;
            };

            return {
              key: id,
              id,
              location_name,
              location_order_id,
              address: getAddress(),
              brand,
              longitude,
              latitude,
              phone,
              vsf_store_id,
              collection: (
                <div class="table-collection-column">
                  <a-checkbox checked={!!collection}></a-checkbox>
                </div>
              ),
              delivery: (
                <div class="table-delivery-column">
                  <a-checkbox checked={!!delivery}></a-checkbox>
                </div>
              ),
              fitment: (
                <div class="table-fitment-column">
                  <a-checkbox checked={!!fitment}></a-checkbox>
                </div>
              ),
              is_hub: (
                <div class="table-collection-column">
                  <a-checkbox checked={!!is_hub}></a-checkbox>
                </div>
              ),
              action: (
                <div class="table-actions">
                  <>
                    <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(id)}>
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteLocation', { id })}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default clientLocationsTable;
</script>
