<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 10 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '../../styled';
import { computed } from 'vue';
import { useStore } from 'vuex';
// import { DataService } from '@/config/dataService/dataService';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  // {
  //   title: 'URL',
  //   dataIndex: 'url',
  //   key: 'url',
  // },
  // {
  //   title: 'Image Base URL',
  //   dataIndex: 'image_base_url',
  //   key: 'image_base_url',
  // },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const ClientsTable = {
  name: 'ClientsTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const storeviews = computed(() => state.clients.clients);
    const isLoading = computed(() => state.clients.loading);

    const tableData = computed(() =>
      storeviews.value.map((product) => {
        const { id, name } = product;

        const to = {
          name: 'omni-client-information',
          params: { id },
        };

        return {
          key: id,
          id,
          name,
          // url,
          // image_base_url,
          action: (
            <div class="table-actions">
              <>
                <router-link to={to}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="edit" size="16" />
                  </sdButton>
                </router-link>
                <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteClient', { id })}>
                  <sdButton class="btn-icon" type="default" shape="circle">
                    <sdFeatherIcons type="trash" size="16" />
                  </sdButton>
                </a-popconfirm>
              </>
            </div>
          ),
        };
      }),
    );

    return {
      tableColumns,
      tableData,
      isLoading,
    };
  },
};

export default ClientsTable;
</script>
