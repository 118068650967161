<template>
  <CreateProductWizardWrapper>
    <WizardWrapper>
      <Steps
        isSwitch
        :current="current"
        :status="status"
        :steps="steps"
        :loading="loading"
        @onNext="next"
        @onPrev="prev"
        @onDone="done"
        :isFinished="isFinished"
      >
        <template #product_type>
          <BasicFormWrapper class="basic-form-inner">
            <div class="atbd-form-checkout">
              <a-row justify="center">
                <a-col :sm="22" :xs="24">
                  <a-alert :message="alertText" class="alertText" type="info" show-icon />
                </a-col>
              </a-row>
              <a-row justify="center">
                <a-col :sm="22" :xs="24" class="mb-15">
                  <div class="set-product-configuration">
                    <sdHeading as="h4">1. Select Product Type</sdHeading>
                    <a-radio-group v-model:value="stateProductType.attribute_set_id">
                      <a-radio-button
                        v-for="attributeGroup in attribute_ids"
                        :key="attributeGroup.attribute_id"
                        :value="attributeGroup.attribute_id"
                      >
                        {{ attributeGroup.name }}
                      </a-radio-button>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
              <a-row justify="center" class="mt-20">
                <a-col :sm="22" :xs="24">
                  <div class="set-product-configuration">
                    <sdHeading as="h4">2. Select Product Configuration</sdHeading>
                    <a-radio-group v-model:value="stateProductType.type_id">
                      <a-radio-button value="simple"> Simple Product </a-radio-button>
                      <a-radio-button value="configurable"> Product with Options </a-radio-button>
                    </a-radio-group>
                  </div>
                </a-col>
              </a-row>
            </div>
          </BasicFormWrapper>
        </template>
        <template #sku>
          <BasicFormWrapper class="basic-form-inner">
            <div class="atbd-form-checkout">
              <a-row justify="center">
                <a-col :sm="22" :xs="24">
                  <sdHeading as="h4">2. Check your product SKU</sdHeading>

                  <a-form :model="stateSku" name="account">
                    <a-form-item name="sku" label="Part Number/SKU:">
                      <a-input v-model:value="stateSku.sku" placeholder="product sku" />
                    </a-form-item>
                  </a-form>

                  <sdAlerts
                    v-if="stateSku.hasIsEpcFlg !== undefined && stateSku.hasIsEpcFlg"
                    :outlined="false"
                    :showIcon="false"
                    message="Warning"
                    description="This product already exists in the Parts Database. Continue if you wish to enhance this product."
                    type="warning"
                  />
                  <sdAlerts
                    v-if="stateSku.hasIsEpcFlg !== undefined && !stateSku.hasIsEpcFlg"
                    :outlined="false"
                    :showIcon="false"
                    message="Error"
                    description="Cannot create new product, because a product already exists with this SKU / Part Number"
                    type="error"
                  />
                </a-col>
              </a-row>
            </div>
          </BasicFormWrapper>
        </template>
        <template #details>
          <BasicFormWrapper class="basic-form-inner">
            <div class="atbd-form-checkout">
              <a-row justify="center">
                <a-col :sm="22" :xs="24">
                  <sdHeading as="h4">3. Product Details</sdHeading>
                  <a-form :model="stateProductDetail" name="productDetails">
                    <a-row :gutter="20">
                      <a-col :sm="12" :xs="24">
                        <a-form-item label="Name / Title">
                          <a-input v-model:value="stateProductDetail.name" size="small" />
                        </a-form-item>
                      </a-col>
                      <a-col v-if="stateProductType.type_id !== 'configurable'" :sm="12" :xs="24">
                        <a-form-item label="Price">
                          <a-input type="number" min="0" v-model:value="stateProductDetail.price" size="small" />
                        </a-form-item>
                      </a-col>
                    </a-row>
                    <a-row :gutter="20" class="mt-20">
                      <a-col :xs="24">
                        <a-form-item label="Disabled / Enabled">
                          <a-switch v-model:checked="stateProductDetail.status" />
                        </a-form-item>
                      </a-col>
                      <!-- <a-col :sm="12" :xs="24"> -->
                      <!--TODO: sync to child attributes -->
                      <!-- <a-form-item label="Attribute Set ID">
                          <a-input size="small" :value="stateProductDetail.attribute_set_id" />
                        </a-form-item>
                      </a-col> -->
                    </a-row>
                    <a-row v-if="stateProductType.type_id === 'configurable'" :gutter="20" class="mt-20">
                      <a-col :xs="24">
                        <a-form-item label="Child Attributes">
                          <a-select
                            mode="multiple"
                            placeholder="Select child attributes to show on parent products"
                            v-model:value="stateProductDetail.child_attributes"
                          >
                            <a-select-option v-for="attr in availableChildAttrs" :key="attr">
                              {{ attr.default_frontend_label }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                    </a-row>
                  </a-form>
                </a-col>
              </a-row>
            </div>
          </BasicFormWrapper>
        </template>
      </Steps>
    </WizardWrapper>
  </CreateProductWizardWrapper>
</template>
<script>
import { FigureWizards, WizardWrapper, ProductTable, OrderSummary, CreateProductWizardWrapper } from '../style';
import { BasicFormWrapper } from '@/view/styled';
import Steps from '@/components/steps/steps';
import { useStore } from 'vuex';
import { computed, ref, watchEffect, reactive, watch, onMounted } from 'vue';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';
import { DataService } from '@/config/dataService/dataService';
import { Modal } from 'ant-design-vue';

const columns = [
  {
    title: 'Product',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
  },
];

const CreateProductWizard = {
  name: 'CreateProductWizard',
  components: {
    Steps,
    FigureWizards,
    WizardWrapper,
    BasicFormWrapper,
    ProductTable,
    OrderSummary,
    PlusOutlined,
    MinusOutlined,
    CreateProductWizardWrapper,
  },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.cart.loading);
    const status = ref('process');
    const isFinished = ref(false);
    const current = ref(0);
    const activeStoreView = computed(() => state.auth.activeStoreView);
    const alertText = computed(() => {
      let activeStoreView = state.auth.activeStoreView;
      return `You are creating a product on the ${activeStoreView.code} store`;
    });
    const attribute_ids = computed(() => {
      let { attributes_group } = state.auth.user;
      return attributes_group?.filter((attributeGroup) => {
        let result = attributeGroup?.storeviews?.filter((store_view) => {
          return store_view.store_view.toLowerCase() === activeStoreView.value.code.toLowerCase();
        });
        return result?.length > 0 ? false : true;
      });
    });
    console.log(attribute_ids, 'attribute_ids');
    const availableChildAttrs1 = computed(() => {
      let attributes = state.auth.user.attributes;
      return attributes.filter((data) => data.used_as_product_option);
    });
    console.log(availableChildAttrs1, 'availableAttributes1');
    const steps = ref([
      {
        title: 'Product Type',
        content: 'product_type',
        status: '',
      },
      {
        title: 'SKU/Part Number',
        content: 'sku',
        status: '',
      },
      {
        title: 'Details',
        content: 'details',
        status: '',
      },
    ]);

    const stateSku = reactive({
      sku: '',
      hasIsEpcFlg: undefined,
    });

    const stateProductType = reactive({
      type_id: '',
      attribute_set_id: attribute_ids.value?.length ? attribute_ids.value[0].attribute_id : 4,
    });

    const availableChildAttrs = ref([]);
    const stateProductDetail = reactive({
      name: '',
      price: 0,
      visibility: 4,
      status: false,
      child_attributes: [],
    });

    const next = async () => {
      if (current.value === 0) {
        if (stateProductType.type_id === 'configurable') {
          // TODO: 4 should be dynamic
          loading.value = true;
          const {
            data: { data, status },
          } = await DataService.get(
            `${activeStoreView.value.code}/products/attribute-sets/${stateProductType.attribute_set_id}/attributes`,
          );

          if (status === 'success') {
            availableChildAttrs.value = data.filter((attr) => {
              let childs = availableChildAttrs1.value.filter((att) => {
                return att.code.toLowerCase() === attr.attribute_code.toLowerCase();
              });
              return attr.is_user_defined && attr.frontend_input === 'select' && !!childs.length;
            });
          }
          stateSku.sku = new Date().valueOf();
          current.value += 2;

          loading.value = false;
        } else {
          current.value++;
        }
      } else if (current.value === 1) {
        if (!stateSku.sku) return;
        loading.value = true;
        try {
          const { data } = await DataService.get(`${activeStoreView.value.code}/products/${stateSku.sku}`);
          if (data.status === 'success') {
            const attributes = data.data.custom_attributes;
            const isEpc = attributes.find((attribute) => attribute.attribute_code === 'is_epc');
            stateSku.hasIsEpcFlg = !!isEpc;
            if (stateSku.hasIsEpcFlg) {
              current.value = current.value + 2;
              steps.value[1].status = 'wait';
              steps.value[1].class = 'manual-wait';
            }
            loading.value = false;
          }
        } catch (e) {
          loading.value = false;
          current.value++;
        }
      } else {
        current.value++;
      }
      status.value = 'process';
    };

    const prev = () => {
      status.value = 'process';
      if (current.value === 2 && stateProductType.type_id === 'configurable') {
        current.value = 0;
        stateSku.sku = '';
      } else {
        if (steps.value[1].status === 'wait') {
          current.value = current.value - 2;
          steps.value[1].status = '';
          steps.value[1].class = '';
        } else current.value--;
      }
    };

    const done = () => {
      console.log(activeStoreView, 'activeStoreView');
      Modal.confirm({
        title: `Are you sure you wan to create this product?`,
        async onOk() {
          loading.value = true;
          console.log(stateProductDetail.status, 'status');
          const payload = {
            sku: stateSku.sku,
            name: stateProductDetail.name,
            price: stateProductDetail.price,
            attribute_set_id: stateProductType.attribute_set_id,
            status: stateProductDetail.status ? 1 : 0,
            visibility: 4,
            type_id: stateProductType.type_id,
            extension_attributes: {
              website_ids: [activeStoreView.value.website_id],
            },
            custom_attributes: [
              {
                attribute_code: 'enhanced_title',
                value: stateProductDetail.name,
              },
            ],
          };

          try {
            await dispatch('createProduct', { data: payload });
            const options = stateProductDetail.child_attributes.map((attr, index) => {
              return {
                attribute_id: attr.attribute_id,
                label: attr.default_frontend_label,
                position: index,
                is_use_default: true,
                values: [
                  {
                    value_index: new Date().valueOf(),
                  },
                ],
              };
            });

            if (stateProductType.type_id === 'configurable') {
              if (options?.length) {
                options.map(async (option) => {
                  await DataService.post(
                    `${activeStoreView.value.code}/configurable-products/${stateSku.sku}/options`,
                    {
                      options: [option],
                    },
                  );
                });
              }
            }

            stateSku.sku = '';
            stateProductType.type_id = '';
            stateProductType.attribute_set_id = attribute_ids.value?.length ? attribute_ids.value[0].attribute_id : 4;
            stateProductDetail.name = '';
            stateProductDetail.price = 0;
            stateProductDetail.visibility = 4;
            stateProductDetail.status = 1;
            stateProductDetail.child_attributes = [];
            loading.value = false;
          } catch (e) {
            loading.value = false;
          }

          status.value = 'process';
          current.value = 0;
          loading.value = false;
        },
        onCancel() {},
      });
    };

    watchEffect(() => {
      const activeElement = document.querySelectorAll('.ant-steps-item-active');
      const successElement = document.querySelectorAll('.ant-steps-item-finish');

      activeElement.forEach((element) => {
        if (element.previousSibling) {
          const bgImage = element.previousSibling;
          if (bgImage.classList.contains('success-step-item')) {
            bgImage.classList.remove('success-step-item');
          } else {
            bgImage.classList.remove('wizard-step-item');
          }
          bgImage.classList.add('wizard-steps-item-active');
        }
      });

      successElement.forEach((element) => {
        if (element.previousSibling) {
          const bgImage = element.previousSibling;
          bgImage.classList.remove('wizard-steps-item-active');
          bgImage.classList.add('success-step-item');
        }
      });
    });

    watch(
      () => stateSku.sku,
      (val) => {
        if (val) stateSku.hasIsEpcFlg = undefined;
      },
    );

    // watch(activeStoreView.value, val => {}, { deep: true });

    onMounted(() => {
      stateProductType.attribute_set_id = attribute_ids.value?.length ? attribute_ids.value[0].attribute_id : 4;
    });

    const loading = ref(false);

    return {
      done,
      prev,
      next,
      columns,
      isLoading,
      status,
      isFinished,
      current,
      loading,
      steps,
      stateSku,
      stateProductType,
      stateProductDetail,
      availableChildAttrs,
      availableChildAttrs1,
      attribute_ids,
      alertText,
    };
  },
};

export default CreateProductWizard;
</script>
