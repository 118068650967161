<template>
  <InfoWraper>
    <!-- <Message />
    <Notification /> -->
    <!-- <div v-if="!isAdmin" class="active-store-view">
      <span>{{ activeStoreView.code }}</span>
    </div> -->
    <div v-if="!isAdmin" class="nav-author">
      <sdDropdown placement="bottomRight" :action="['click']">
        <template v-slot:overlay>
          <NavAuth>
            <a
              v-for="storeView in user.store_views"
              :key="storeView.id"
              @click.prevent="() => updateStoreView(storeView)"
              to="#"
            >
              <!-- <img :src="require('../../../static/img/flag/english.png')" alt="storeview_image" /> -->
              <span>{{ storeView?.code?.toUpperCase() }}</span>
            </a>
          </NavAuth>
        </template>
        <a to="#" class="head-example active-store-view">
          <span>{{ activeStoreView.code?.toUpperCase() }}</span>
        </a>
      </sdDropdown>
    </div>

    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info">
                <figcaption>
                  <sdHeading as="h5">{{ user ? user.name : '' }}</sdHeading>
                </figcaption>
              </figure>
              <a @click.prevent="SignOut" class="user-dropdwon__bottomAction" href="#"><LogoutOutlined />Sign Out</a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <AvatarWraperStyle>
            <a-avatar class="ant-avatar" :style="{ backgroundColor: '#f56a00' }">
              {{ avatarName }}
            </a-avatar>
          </AvatarWraperStyle>
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, NavAuth, UserDropDwon, AvatarWraperStyle } from './auth-info-style';
// import Support from './Support';
// import Settings from './Settings';
// import Notification from './Notification';
// import Message from './Message';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import app from '@/config/configApp';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    NavAuth,
    UserDropDwon,
    // Notification,
    // Message,
    LogoutOutlined,
    AvatarWraperStyle,
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const { $cookies } = app.config.globalProperties;
    const { state, dispatch, commit } = useStore();
    const user = computed(() => state.auth.user);
    const isAdmin = computed(() => state.auth.user && !!state.auth.user.is_admin);

    const activeStoreView = ref($cookies.get('active_store_view'));
    const { push } = useRouter();
    // const route = useRoute();
    const SignOut = async () => {
      await dispatch('logOut');
      push({ name: 'login' });
    };

    const avatarName = computed(() => {
      const nameAry = user.value.name.split(' ');
      return nameAry.map((name) => name.charAt(0).toUpperCase()).join('');
    });

    const updateStoreView = async (storeView) => {
      // let oldStoreView = activeStoreView.value;
      commit('setActiveStoreView', storeView);
      activeStoreView.value = storeView;
      $cookies.set('active_store_view', storeView);
      const scopes = user.value.scopes.map((scope) => scope.name);
      const productData = computed(() => state.omni.product);

      if (scopes.includes('orders')) {
        await dispatch('fetchOrders');
      }
      if (scopes.includes('customers')) {
        await dispatch('fetchCustomers');
      }
      if (scopes.includes('products')) {
        await dispatch('fetchProducts');
      }
      if (productData.value?.sku) await dispatch('fetchProduct', { sku: productData.value.sku });
      await dispatch('fetchEnquiries');
      await dispatch('setInitFilters');
      // if (
      //   oldStoreView?.id !== storeView?.id &&
      //   (route.name === 'omni-product-details' ||
      //     route.name === 'omni-national-codes' ||
      //     route.name === 'omni-media-upload' ||
      //     route.name === 'omni-child-products')
      // ) {
      //   push({ name: 'omni-products' });
      // }
    };

    if (!activeStoreView?.value) {
      if (user.value?.store_views?.length) {
        // activeStoreView?.value = user.value.store_views[0];
        updateStoreView(user.value.store_views[0]);
      }
    }

    return {
      SignOut,
      user,
      activeStoreView,
      updateStoreView,
      isAdmin,
      avatarName,
    };
  },
};
</script>
