<template>
  <sdPageHeader title="Appointment Create" />
  <Main>
    <sdCards>
      <template #button>
        <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving">Save</sdButton>
      </template>
      <FileCardWrap>
        <BasicFormWrapper>
          <div v-if="isSaving || isLoading" class="spin">
            <a-spin />
          </div>
          <a-form v-else name="multi-form" layout="horizontal">
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Customer">
                  <a-input v-model:value="appointment.customer" placeholder="Please type your customer name." />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Order ID">
                  <a-input v-model:value="appointment.order_id" placeholder="Please type your order id." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="24" :xs="24" class="mb-25">
                <a-form-item label="Note">
                  <a-input v-model:value="appointment.note" placeholder="Please type your note." />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Technician">
                  <a-select v-model:value="appointment.technician_id" mode="multiple">
                    <a-select-option :value="technician.id" :key="technician.id" v-for="technician in technicianData">{{
                      technician.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </BasicFormWrapper>
      </FileCardWrap>
    </sdCards>
  </Main>
</template>

<script>
import { FileCardWrap } from './style';
import { computed, onMounted } from 'vue';
import { BasicFormWrapper, Main } from '@/view/styled';
import { useStore } from 'vuex';
// import dayjs from 'dayjs';
// import CommentsHistoryVue from '../../ordersTable/overview/order/CommentsHistory.vue';
import { useRoute } from 'vue-router';
// import AppointmentDetails from './Index.vue';

export default {
  name: 'AppointmentDetails',
  components: { FileCardWrap, BasicFormWrapper, Main },
  setup() {
    const { state, dispatch, commit } = useStore();
    const { params } = useRoute();
    const appointment = computed(() => state.appointments.appointmentData);
    const slotData = computed(() => state.appointments.slotData);
    const technicianData = computed(() => state.appointments.technicianData);

    const onSave = () => {
      dispatch('setAppointment', appointment.value);
    };

    onMounted(async () => {
      let date = new Date(new Date().toISOString().split('T')[0]).toISOString();
      await dispatch('fetchSlotIDS', date);
      await dispatch('fetchTechnicians');
      if (params.id === 'new') {
        commit('initAppointmentData');
      } else {
        await dispatch('fetchAppointmentData', params.id);
      }
    });

    return {
      appointment,
      slotData,
      technicianData,
      onSave,
    };
  },
};
</script>
