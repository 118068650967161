<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 5 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '@/view/styled';
import { computed } from 'vue';
import { useStore } from 'vuex';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Attribute Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Attribute Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Used as option',
    dataIndex: 'used_as_product_option',
    key: 'used_as_product_option',
  },
  {
    title: 'Group',
    dataIndex: 'group',
    key: 'group',
  },
  {
    title: 'Variant Product Field',
    dataIndex: 'variant_product_field',
    key: 'variant_product_field',
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
];

const clients = [
  { key: 1, name: 'Product Information' },
  { key: 2, name: 'Pricing' },
  { key: 3, name: 'Shipping' },
  { key: 4, name: 'Options' },
];

const clientUsersTable = {
  name: 'ClientAttributesTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const attributes = computed(() => state.clients.client.attributes);
    const isLoading = computed(() => state.clients.loading);

    const onEdit = (id) => {
      const originAttribute = attributes.value ? attributes.value.find((user) => user.id === id) : null;
      if (originAttribute) {
        commit('setAttribute', originAttribute);
      } else {
        commit('initAttribute');
      }
      commit('toggleAttributeEditModal', true);
    };

    const tableData = computed(() =>
      attributes.value
        ? attributes.value.map((attribute) => {
            const { id, name, code, used_as_product_option, group, variant_product_field, details } = attribute;
            let row = clients.filter((data) => data.key === group)[0];
            return {
              id,
              name,
              code,
              used_as_product_option: used_as_product_option ? 'Yes' : 'No',
              group: row ? row.name : '',
              variant_product_field: variant_product_field ? 'Yes' : 'No',
              details,
              action: (
                <div class="table-actions">
                  <>
                    <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(id)}>
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteAttribute', { id })}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default clientUsersTable;
</script>
