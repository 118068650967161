<template>
  <!-- <OrderInformation /> -->
  <!-- <AddressInformation /> -->
  <!-- <PaymentMethod ref="paymentMethod" /> -->
  <ItemsToRefundAndTotal />
</template>

<script>
// import OrderInformation from '../../information/OrderInformation.vue';
// import AddressInformation from '../../information/AddressInformation.vue';
// import PaymentMethod from '../../information/PaymentMethod.vue';
import ItemsToRefundAndTotal from './ItemsToRefundAndTotal.vue';

export default {
  name: 'OmniOrderInvoiceRefund',
  components: {
    // OrderInformation,
    // AddressInformation,
    // PaymentMethod,
    ItemsToRefundAndTotal,
  },
};
</script>
