<template>
  <Main>
    <ProductWrapper>
      <a-row class="justify-content-center" :gutter="25">
        <div v-if="responsive > 991" class="sidebar-card">
          <sdCards headless>
            <div class="note-sidebar-bottom">
              <NoteNav>
                <ul>
                  <li>
                    <router-link :to="`${path}/product-details`">
                      <sdFeatherIcons type="edit" size="18" />
                      <span class="nav-text">
                        <span>Product Details</span>
                      </span>
                    </router-link>
                  </li>
                  <li v-if="productData.type_id === 'configurable'">
                    <router-link :to="`${path}/child-products`">
                      <sdFeatherIcons type="list" size="18" />
                      <span class="nav-text">
                        <span>Child Products</span>
                      </span>
                    </router-link>
                  </li>
                  <li v-if="user.client_id !== 28">
                    <router-link :to="`${path}/national-codes`">
                      <sdFeatherIcons type="stop-circle" size="18" />
                      <span class="nav-text">
                        <span>Vehicle Fitment</span>
                      </span>
                    </router-link>
                  </li>
                  <li v-if="productData.attribute_set_id === 9">
                    <router-link :to="`${path}/tyres`">
                      <sdFeatherIcons type="stop-circle" size="18" />
                      <span class="nav-text">
                        <span>Tyre Fitment</span>
                      </span>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`${path}/media-upload`">
                      <sdFeatherIcons type="image" size="18" />
                      <span class="nav-text">
                        <span>Media</span>
                      </span>
                    </router-link>
                  </li>
                </ul>
              </NoteNav>
              <!-- <sdButton type="warning" block @click="goToSite"> Preview Product </sdButton> -->
            </div>
          </sdCards>
        </div>
        <a-col class="product-body" :xxl="24" :xl="24" :lg="24" :xs="24">
          <router-view></router-view>
        </a-col>
        <div v-if="responsive > 991" class="sidebar-card--right">
          <sdCards headless>
            <div class="status-bar">
              <a-row class="m-15 mt-25">
                <a-col :xs="24" class="mb-15">
                  <div class="status-bar-circle">
                    <h3>Status</h3>
                    <div class="status-circle" :class="{ enabled: status === 1 }"></div>
                  </div>
                </a-col>
                <a-col :xs="24">
                  <a-select size="large" :style="{ width: '100%' }" :value="status" @change="changeProductState">
                    <a-select-option key="enabled" :value="1"> Enabled </a-select-option>
                    <a-select-option key="disabled" :value="2"> Disabled </a-select-option>
                  </a-select>
                </a-col>
              </a-row>
              <a-row class="m-15 mt-25">
                <a-col :xs="24" class="language"> Language: {{ activeStoreView.language }} </a-col>
                <a-col :xs="24" class="language">
                  Currency: {{ `${activeStoreView.currency} (${activeStoreView.currency_code})` }}
                </a-col>
              </a-row>
              <a-row class="m-15 mt-25">
                <a-col :xs="24">
                  <h2>Channel Visibility</h2>
                  <a-checkbox :checked="true" v-for="website in websitesData" :key="website.id">
                    {{ website.name }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </div>
          </sdCards>
        </div>
      </a-row>
    </ProductWrapper>
  </Main>
</template>
<script>
import { ProductWrapper, NoteNav } from './style';
import { Main } from '@/view/styled';
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// import { stat } from 'fs';

export default {
  name: 'ProductPage',
  components: { ProductWrapper, Main, NoteNav },
  setup() {
    const { state, dispatch, commit } = useStore();
    const responsive = ref(0);
    const collapsed = ref(false);
    const activeStoreview = computed(() => state.auth.activeStoreView);
    const user = computed(() => state.auth.user);
    const activeStoreView = computed(() => state.auth.activeStoreView);

    const toggleCollapsed = () => {
      collapsed.value = !collapsed.value;
    };
    const collapseSidebar = () => {
      collapsed.value = false;
    };

    const { params } = useRoute();
    const path = `/omni/product/${params.sku}`;
    const title = computed(() => (params.sku === 'new' ? 'New product' : 'Product'));

    const productData = computed(() => state.omni.product);
    const websitesData = computed(() => {
      const websites = state.omni.websites;
      const websiteIds = state.omni.product?.extension_attributes?.website_ids;
      if (websiteIds?.length) {
        return websites.filter((website) => websiteIds.includes(website.id));
      } else {
        return [];
      }
    });

    const status = computed(() => {
      let status = state.omni.product.status;
      if (status === 1) return 1;
      else return 2;
    });

    const changeProductState = (value) => {
      commit('setProductStatus', value);
      commit('setStateChanged', value);
    };

    onMounted(async () => {
      function updateSize() {
        const width = window.innerWidth;
        responsive.value = width;
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      dispatch('websites');

      if (params.sku === 'new') {
        const productData = computed(() => state.omni.product);
        productData.value = {};
      } else {
        await dispatch('fetchProduct', {
          sku: params.sku,
        });
      }
    });

    const goToSite = () => {
      const productUrl = productData.value.custom_attributes.find((attr) => attr.attribute_code === 'url_key');
      if (productUrl && activeStoreview.value.vsf_url) {
        const url = `${activeStoreview.value.vsf_url}/${productUrl.value}?preview=${activeStoreview.value.vsf_preview}`;
        window.open(url, '_blank');
      }
    };
    console.log(user, 'user');
    return {
      collapseSidebar,
      toggleCollapsed,
      collapsed,
      responsive,
      path,
      title,
      productData,
      goToSite,
      user,
      activeStoreView,
      status,
      changeProductState,
      websitesData,
    };
  },
};
</script>
