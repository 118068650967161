<template>
  <sdCards title="Order Invoices">
    <!-- <template #button>
      <sdButton class="btn-signin" type="warning" @click="goRefundPage">Credit Memo</sdButton>
    </template> -->
    <FileCardWrap>
      <a-row :gutter="15">
        <a-col :xs="24">
          <TableWrapper class="table-responsive">
            <a-table :dataSource="tableData" :columns="tableColumns" :pagination="{ defaultPageSize: 5 }" />
          </TableWrapper>
        </a-col>
      </a-row>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { FileCardWrap } from './style';
import { TableWrapper } from '@/view/styled';
import { useStore } from 'vuex';
import { computed } from 'vue';

const tableColumns = [
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoice_date',
    key: 'invoice_date',
  },
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
  },
  {
    title: 'Order Date',
    dataIndex: 'order_date',
    key: 'orde_date',
  },
  {
    title: 'Bill-to Name',
    dataIndex: 'bill_to_name',
    key: 'bill_to_name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
export default {
  components: {
    FileCardWrap,
    TableWrapper,
  },
  setup() {
    const { state } = useStore();
    const invoices = computed(() => state.orders.invoices);
    const order = computed(() => state.orders.order);

    const tableData = computed(() =>
      invoices.value.length
        ? invoices.value.map((item) => {
            const { entity_id, increment_id, updated_at, state, grand_total } = item;

            const to = {
              name: 'omni-order-invoice-view-create',
              params: { id: order.value.entity_id, invoiceId: entity_id },
            };

            return {
              key: entity_id,
              invoice: increment_id,
              invoice_date: updated_at,
              order: order.value.increment_id,
              order_date: order.value.updated_at,
              bill_to_name: order.value.billing_address.firstname + ' ' + order.value.billing_address.lastname,
              status: state === 2 ? 'paid' : 'not paid',
              amount: grand_total,
              action: (
                <div class="table-actions">
                  <>
                    <router-link to={to}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="edit" size="16" />
                      </sdButton>
                    </router-link>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableData,
      tableColumns,
    };
  },
};
</script>
