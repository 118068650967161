<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 5 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '@/view/styled';
import { computed } from 'vue';
import { useStore } from 'vuex';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Is Admin?',
    dataIndex: 'is_admin',
    key: 'is_admin',
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
  },
  {
    title: 'Scopes',
    dataIndex: 'scopes',
    key: 'scopes',
  },
  {
    title: 'Storeviews',
    dataIndex: 'store_views',
    key: 'store_views',
  },
  {
    title: 'Locations',
    dataIndex: 'locations',
    key: 'locations',
  },
  {
    title: 'Email Receiving Option',
    dataIndex: 'email_only',
    key: 'email_only',
  },
  {
    title: 'MFA',
    dataIndex: 'mfa',
    key: 'mfa',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const clientUsersTable = {
  name: 'ClientUsersTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const users = computed(() => state.clients.client.users);
    const isLoading = computed(() => state.clients.loading);

    const onEdit = (id) => {
      const originUser = users.value ? users.value.find((user) => user.id === id) : null;
      if (originUser) {
        const user = Object.assign({}, originUser);
        user.scopes = user.scopes.map((scope) => scope.name);
        user.roles = user.roles.map((role) => role.name);
        user.store_views = user.store_views.map((storeview) => storeview.code);
        user.locations = user.locations.map((location) => location.location_name);
        commit('setUser', user);
      } else {
        commit('initUser');
      }
      commit('toggleUserEditModal', true);
    };

    const tableData = computed(() =>
      users.value
        ? users.value.map((user) => {
            const { id, is_admin, name, email, roles, scopes, store_views, locations, email_only, mfa } = user;

            return {
              key: id,
              id,
              name,
              email,
              roles: roles.map((role) => role.name).join(','),
              scopes: scopes.map((scope) => scope.name).join(','),
              store_views: store_views.map((storeview) => storeview.code).join(','),
              locations: locations.map((location) => location.location_name).join(','),
              is_admin: (
                <div class="table-is-admin-column">
                  <a-checkbox checked={!!is_admin}></a-checkbox>
                </div>
              ),
              email_only: email_only ? 'Yes' : 'No',
              mfa: mfa ? 'Yes' : 'No',
              action: (
                <div class="table-actions">
                  <>
                    <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(id)}>
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteUser', { id })}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default clientUsersTable;
</script>
