<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 5 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '@/view/styled';
import { computed } from 'vue';
import { useStore } from 'vuex';

const tableColumns = [
  {
    title: 'Index',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Restricted StoreView',
    dataIndex: 'store_views',
    key: 'store_views',
  },
  {
    title: 'Attribute ID',
    dataIndex: 'attribute_id',
    key: 'attribute_id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
];

const clientUsersTable = {
  name: 'ClientAttributesGroupTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const attributes = computed(() => state.clients.client.attribute_groups);
    const isLoading = computed(() => state.clients.loading);

    const onEdit = (id) => {
      let originAttribute = attributes.value ? attributes.value.find((user) => user.id === id) : null;
      let storeviews = originAttribute.storeviews;
      if (storeviews?.length) {
        originAttribute.store_views = storeviews.map((d) => d.store_view);
      }
      if (originAttribute) {
        commit('setAttributeGroup', originAttribute);
      } else {
        commit('initAttributeGroup');
      }
      commit('toggleAttributeGroupEditModal', true);
    };

    const tableData = computed(() =>
      attributes.value
        ? attributes.value.map((attribute, index) => {
            const { id, attribute_id, name, storeviews } = attribute;
            console.log(
              storeviews.map((item) => item.store_view),
              'storeviews',
            );
            return {
              index: index + 1,
              store_views: storeviews?.length ? storeviews.map((item) => item.store_view).join(',') : '',
              attribute_id,
              name,
              action: (
                <div class="table-actions">
                  <>
                    <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(id)}>
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                    <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteAttributeGroup', { id })}>
                      <sdButton class="btn-icon" type="default" shape="circle">
                        <sdFeatherIcons type="trash" size="16" />
                      </sdButton>
                    </a-popconfirm>
                  </>
                </div>
              ),
            };
          })
        : [],
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default clientUsersTable;
</script>
