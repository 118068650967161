<template>
  <sdCards title="Client Information">
    <template #button>
      <sdButton class="btn-signin" type="primary" @click="onSave" :disabled="isSaving">Save</sdButton>
    </template>
    <FileCardWrap>
      <BasicFormWrapper>
        <div v-if="isSaving" class="spin">
          <a-spin />
        </div>
        <a-form v-else name="multi-form" layout="horizontal">
          <a-row :gutter="30">
            <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item label="Name">
                <a-input tabindex="1" v-model:value="clientData.name" />
              </a-form-item>
              <a-form-item label="Consumer Key">
                <a-input tabindex="3" v-model:value="clientData.consumer_key" />
              </a-form-item>
              <a-form-item label="Token">
                <a-input tabindex="5" v-model:value="clientData.token" />
              </a-form-item>
              <a-form-item label="Image Base URL">
                <a-input tabindex="7" v-model:value="clientData.image_base_url" />
              </a-form-item>
            </a-col>
            <a-col :sm="12" :xs="24" class="mb-25">
              <a-form-item label="Url">
                <a-input tabindex="2" v-model:value="clientData.url" />
              </a-form-item>
              <a-form-item label="Consumer Secret">
                <a-input tabindex="4" v-model:value="clientData.consumer_secret" />
              </a-form-item>
              <a-form-item label="Token Secret">
                <a-input tabindex="6" v-model:value="clientData.token_secret" />
              </a-form-item>
              <!-- <a-form-item label="Webhook Token">
                <a-input tabindex="6" v-model:value="clientData.webhook_token" />
              </a-form-item> -->
            </a-col>
          </a-row>
        </a-form>
      </BasicFormWrapper>
    </FileCardWrap>
  </sdCards>
</template>

<script>
import { FileCardWrap } from './style';
import { computed } from 'vue';
import { BasicFormWrapper } from '@/view/styled';
import { useStore } from 'vuex';
// import { useRoute } from 'vue-router';

export default {
  name: 'ClientInformation',
  components: { FileCardWrap, BasicFormWrapper },
  setup() {
    const { state, dispatch } = useStore();
    // const { params } = useRoute();
    const clientData = computed(() => state.clients.client);
    const isLoading = computed(() => state.clients.loading);

    const onSave = async () => {
      if (clientData.value.id) {
        await dispatch('updateClient', { id: clientData.value.id });
      } else {
        await dispatch('createClient');
      }
    };

    return {
      clientData,
      onSave,
      isLoading,
    };
  },
};
</script>
